import { create } from "zustand";

interface SongImageStore {
  images: object;
  addImage: (id: string, value: string) => void;
}

const songImageStore = create<SongImageStore>((set) => ({
  images: {},
  addImage: (id: string, value: string) =>
    set((state) => ({ images: { ...state.images, [id]: value } })),
}));

export default songImageStore;
