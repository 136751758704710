import axios from "../axios";
//
const doShuffle = (status) =>
  axios.post(`v1/customer/events/shuffle`, {
    shuffle: status,
  });

const changeSong = (payload) =>
  axios.post(`v1/customer/events/song_changed`, payload);

const vonlumeChange = (payload) =>
  axios.post(`v1/customer/events/volume_changed`, payload);

const pauseSong = () => axios.post(`v1/customer/events/song_paused`, {});

const refreshPlaylist = (payload) =>
  axios.post("v1/customer/events/send", payload);

const sendLikedSongStatus = (payload) =>
  axios.post("v1/customer/events/send", payload);

const sendIgnoreSongStatus = (payload) =>
  axios.post("v1/customer/events/send", payload);

export const socketServices = {
  doShuffle,
  changeSong,
  vonlumeChange,
  pauseSong,
  refreshPlaylist,
  sendLikedSongStatus,
  sendIgnoreSongStatus,
};
