import * as React from 'react';
const Search = (props) => (
  <svg
    width={26}
    height={26}
    viewBox="0 0 26 26"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M21.9635 20.9102L17.2991 16.2458C18.5107 14.7639 19.1064 12.873 18.963 10.9643C18.8196 9.05547 17.948 7.27483 16.5285 5.99065C15.1091 4.70646 13.2503 4.01699 11.3368 4.06483C9.42319 4.11268 7.60122 4.89418 6.2477 6.2477C4.89418 7.60122 4.11268 9.42319 4.06483 11.3368C4.01699 13.2503 4.70646 15.1091 5.99065 16.5285C7.27483 17.948 9.05547 18.8196 10.9643 18.963C12.873 19.1064 14.7639 18.5107 16.2458 17.2991L20.9102 21.9635L21.9635 20.9102ZM11.5352 17.4942C10.3566 17.4942 9.20445 17.1447 8.22449 16.4899C7.24453 15.8351 6.48074 14.9045 6.02971 13.8156C5.57869 12.7267 5.46068 11.5286 5.69061 10.3726C5.92054 9.21666 6.48808 8.15486 7.32147 7.32147C8.15486 6.48808 9.21666 5.92054 10.3726 5.69061C11.5286 5.46068 12.7267 5.57869 13.8156 6.02971C14.9045 6.48074 15.8351 7.24453 16.4899 8.22449C17.1447 9.20445 17.4942 10.3566 17.4942 11.5352C17.4924 13.1151 16.864 14.6297 15.7469 15.7469C14.6297 16.864 13.1151 17.4924 11.5352 17.4942Z"
      fill="white"
    />
  </svg>
);
export default Search;
