import { create } from "zustand";

interface ScheduleStore {
  schedulesDone: object;
  markScheduleDone: (scheduleId: string) => void;
}

const SchedulesStore = create<ScheduleStore>((set) => ({
  schedulesDone: new Set(),
  markScheduleDone: (scheduleId: string) => {
    set((state) => {
      const updatedObject = { ...state.schedulesDone, [scheduleId]: true };
      return { schedulesDone: updatedObject };
    });
  },
}));

export default SchedulesStore;
