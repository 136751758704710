import React, { forwardRef, useCallback } from "react";

import Icons from "../icons";
import propTypes from "prop-types";
import cx from "classnames";
import { LoadingOutlined } from "@ant-design/icons";
import _noop from "lodash/noop";
import { Spin } from "antd";

import styles from "./button.module.scss";

export const BUTTON_TYPES = {
  SECONDARY: "SECONDARY",
  TERTIARY: "TERTIARY",
};

const antIcon = (
  <LoadingOutlined
    style={{
      fontSize: 24,
    }}
    spin
  />
);

const Button = (props, ref) => {
  const {
    children,
    isLoading,
    type,
    onClick,
    hasIcon,
    iconType,
    contentClassName,
    className,
    ...rest
  } = props;

  const handleOnChange = useCallback(
    (e) => {
      if (!isLoading) {
        e.preventDefault();
        onClick(e);
      }
    },
    [isLoading, onClick]
  );

  return (
    <div className={cx(styles.container, className)}>
      <button
        ref={ref}
        onClick={handleOnChange}
        className={cx(styles.center, styles.primaryBtn, contentClassName, {
          [styles.secondaryBtn]: type === BUTTON_TYPES.SECONDARY,
          [styles.tertiaryBtn]: type === BUTTON_TYPES.TERTIARY,
        })}
        disabled={isLoading}
        {...rest}
      >
        <div className={styles.btnContent}>
          {hasIcon && !isLoading && (
            <div className={styles.icon}>
              <Icons type={iconType} />
            </div>
          )}
          {isLoading ? <Spin indicator={antIcon} /> : children}
        </div>
      </button>
    </div>
  );
};

Button.propTypes = {
  children: propTypes.string,
  isLoading: propTypes.bool,
  onClick: propTypes.func,
  className: propTypes.string,
  type: propTypes.string,
  hasIcon: propTypes.bool,
  iconType: propTypes.string,
  contentClassName: propTypes.string,
};

Button.defaultProps = {
  children: "",
  isLoading: false,
  className: "",
  onClick: _noop,
  hasIcon: false,
  iconType: "",
  type: "",
  contentClassName: "",
};

export default forwardRef(Button);
