export const ROUTES = {
  HOME: '/',
  PROFILE: '/profile',
  MY_SCHEDULE: '/schedule',
  MY_SCHEDULE_EDIT: '/schedule/:id/edit',
  MY_PLAYLIST_NEW: '/schedule/create',
  LIKED: '/liked',
  MY_PLAYLIST: '/my-playlist',
  NEW_PLAYLIST: '/my-playlist/new',
  MY_PLAYLIST_EDIT: '/my-playlist/view/:id',
  LOGIN: '/login',
};
