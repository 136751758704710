import * as React from 'react';
const MoreInfo = (props) => (
  <svg
    width={38}
    height={38}
    viewBox="0 0 38 38"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M16.9998 12.7062C16.9998 11.7639 17.7594 11 18.6963 11V11C19.6332 11 20.3927 11.7639 20.3927 12.7062C20.3927 13.6486 19.6332 14.4125 18.6963 14.4125C17.7594 14.4125 16.9998 13.6486 16.9998 12.7062V12.7062Z"
      fill="white"
    />
    <path
      d="M16.9999 18.8332C16.9967 18.3797 17.1744 17.9439 17.4933 17.6232C17.8121 17.3025 18.2454 17.1238 18.6963 17.127C19.3824 17.127 20.001 17.5427 20.2635 18.1803C20.5261 18.8178 20.381 19.5517 19.8958 20.0396C19.4106 20.5276 18.681 20.6736 18.0471 20.4095C17.4132 20.1454 16.9999 19.5233 16.9999 18.8332Z"
      fill="white"
    />
    <path
      d="M16.9998 24.9597C16.9998 24.0173 17.7594 23.2534 18.6963 23.2534V23.2534C19.6332 23.2534 20.3927 24.0173 20.3927 24.9597C20.3927 25.902 19.6332 26.6659 18.6963 26.6659C17.7594 26.6659 16.9998 25.902 16.9998 24.9597Z"
      fill="white"
    />
  </svg>
);
export default MoreInfo;
