import React, { forwardRef } from "react";
import propTypes from "prop-types";
import cx from "classnames";

import styles from "./progressBar.module.scss";
import { noop } from "lodash";

// eslint-disable-next-line react/display-name
const ProgressBar = forwardRef(
  ({ onMouseUp, className, onChange, disabled }, ref) => (
    <input
      ref={ref}
      type="range"
      min={0}
      max={1}
      step={0.02}
      // value={value}
      onChange={onChange}
      className={cx(styles.volumeBar, className)}
      onMouseUp={onMouseUp}
      disabled={disabled}
    />
  )
);

ProgressBar.propTypes = {
  onMouseUp: propTypes.func,
  className: propTypes.string,
  onChange: propTypes.func,
};

ProgressBar.defaultProps = {
  onMouseUp: noop,
  className: "",
  onChage: noop,
};

export default ProgressBar;
