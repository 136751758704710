import { isEmpty } from "lodash";
import songImageStore from "../zustand/songImages.store";
console.log(songImageStore.getState().images);

export const fetchSongCoverImage = async (id, onSuccess, onError) => {
  try {
    const images = songImageStore.getState().images;
    if (images[id]) {
      onSuccess(images[id]);
      return;
    }

    const jsmediatags = window.jsmediatags;

    if (!jsmediatags) return;

    const url = `https://server.4-play.io/songs/${id}.mp3`;

    // fethc the cover image of the audio
    const response = await fetch(url);
    const blob = await response.blob();
    const file = new File([blob], "filename.mp3", { type: "audio/mp3" });

    jsmediatags.read(file, {
      onSuccess: function (tag) {
        const { tags } = tag;
        const { picture } = tags;
        if (picture) {
          const base64String = picture.data.reduce(
            (acc, byte) => acc + String.fromCharCode(byte),
            ""
          );
          const base64Image = `data:${picture.format};base64,${window.btoa(
            base64String
          )}`;
          songImageStore.setState((state) => ({
            images: {
              ...state.images,
              [id]: base64Image,
            },
          }));
          onSuccess(base64Image);
        }
      },
      onError: function (error) {
        onError(error);
      },
    });
  } catch (error) {
    onError(error);
  }
};

export function shuffleArray(array, currentSongIndex) {
  if (isEmpty(array)) return array;

  // remove current song from array
  const currentSong = array.splice(currentSongIndex, 1)[0];

  let currentIndex = array.length,
    randomIndex;

  // While there remain elements to shuffle.
  while (currentIndex != 0) {
    // Pick a remaining element.
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex--;

    // And swap it with the current element.
    [array[currentIndex], array[randomIndex]] = [
      array[randomIndex],
      array[currentIndex],
    ];
  }

  // devide song array into two parts from current song index
  const firstPart = array.slice(0, currentSongIndex);
  const secondPart = array.slice(currentSongIndex);
  // cobined them with current song in the middle
  array = [...firstPart, currentSong, ...secondPart];

  return array;
}

let songs = [];
let currentSongIndex = 0;

export function playNextSong() {
  let nextSongIndex = (currentSongIndex + 1) % songs.length;
  while (songs[nextSongIndex].ignore) {
    nextSongIndex = (nextSongIndex + 1) % songs.length;
  }
  currentSongIndex = nextSongIndex;
  playSong(currentSongIndex);
}

export function playPreviousSong() {
  let previousSongIndex = (currentSongIndex - 1 + songs.length) % songs.length;
  while (songs[previousSongIndex].ignore) {
    previousSongIndex = (previousSongIndex - 1 + songs.length) % songs.length;
  }
  currentSongIndex = previousSongIndex;
  playSong(currentSongIndex);
}

function playSong(index) {
  console.log(`Playing ${songs[index].title} with id ${songs[index].id}`);
}

export const getIngoreSongIds = (playlists) => {
  const ignoreSongs = [];
  playlists.forEach((playlist) => {
    playlist.songs.forEach((song) => {
      if (song.ignore) ignoreSongs.push(song.id + playlist.id);
    });
  });
  return ignoreSongs;
};
