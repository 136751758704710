import * as React from 'react';
const Profile = (props) => (
  <svg
    width={26}
    height={26}
    viewBox="0 0 26 26"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M13 4.75C13.9946 4.75 14.9484 5.14509 15.6517 5.84835C16.3549 6.55161 16.75 7.50544 16.75 8.5C16.75 9.49456 16.3549 10.4484 15.6517 11.1517C14.9484 11.8549 13.9946 12.25 13 12.25C12.0054 12.25 11.0516 11.8549 10.3483 11.1517C9.64509 10.4484 9.25 9.49456 9.25 8.5C9.25 7.50544 9.64509 6.55161 10.3483 5.84835C11.0516 5.14509 12.0054 4.75 13 4.75ZM21.25 19.75C21.25 19.7598 21.2489 19.7711 21.2372 19.7931C21.2232 19.8194 21.1906 19.8659 21.1194 19.9282C20.9699 20.0591 20.7128 20.2051 20.3304 20.3485C19.5704 20.6335 18.5154 20.836 17.407 20.9745C16.3098 21.1117 15.2071 21.1808 14.375 21.2155C13.96 21.2328 13.6144 21.2414 13.3733 21.2457C13.2528 21.2479 13.1585 21.2489 13.0948 21.2495L13.0228 21.2499L13.005 21.25L13.0009 21.25L13.0001 21.25H13H13H12.9999L12.9991 21.25L12.995 21.25L12.9772 21.2499L12.9052 21.2495C12.8415 21.2489 12.7472 21.2479 12.6267 21.2457C12.3856 21.2414 12.04 21.2328 11.625 21.2155C10.7929 21.1808 9.69022 21.1117 8.59303 20.9745C7.48457 20.836 6.42955 20.6335 5.66959 20.3485C5.28722 20.2051 5.03013 20.0591 4.8806 19.9282C4.80939 19.8659 4.77685 19.8194 4.76284 19.7931C4.75112 19.7711 4.75 19.7598 4.75 19.75C4.75 18.7908 5.57057 17.5811 7.174 16.5571C8.73211 15.562 10.8429 14.875 13 14.875C15.1571 14.875 17.2679 15.562 18.826 16.5571C20.4294 17.5811 21.25 18.7908 21.25 19.75Z"
      stroke="white"
      strokeWidth={1.5}
    />
  </svg>
);
export default Profile;
