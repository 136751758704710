import * as React from 'react';
const SVGComponent = (props) => (
  <svg
    width={26}
    height={26}
    viewBox="0 0 26 26"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M19.0664 12.1336H13.8664V6.93356C13.8664 6.70371 13.7751 6.48327 13.6126 6.32074C13.45 6.1582 13.2296 6.06689 12.9997 6.06689C12.7699 6.06689 12.5494 6.1582 12.3869 6.32074C12.2244 6.48327 12.1331 6.70371 12.1331 6.93356V12.1336H6.93307C6.70322 12.1336 6.48278 12.2249 6.32025 12.3874C6.15772 12.5499 6.06641 12.7704 6.06641 13.0002C6.06641 13.2301 6.15772 13.4505 6.32025 13.6131C6.48278 13.7756 6.70322 13.8669 6.93307 13.8669H12.1331V19.0669C12.1331 19.2967 12.2244 19.5172 12.3869 19.6797C12.5494 19.8423 12.7699 19.9336 12.9997 19.9336C13.2296 19.9336 13.45 19.8423 13.6126 19.6797C13.7751 19.5172 13.8664 19.2967 13.8664 19.0669V13.8669H19.0664C19.2963 13.8669 19.5167 13.7756 19.6792 13.6131C19.8418 13.4505 19.9331 13.2301 19.9331 13.0002C19.9331 12.7704 19.8418 12.5499 19.6792 12.3874C19.5167 12.2249 19.2963 12.1336 19.0664 12.1336Z"
      fill="#949494"
    />
  </svg>
);
export default SVGComponent;
