import { result } from "lodash";
import axios from "../../axios";
import { getToken } from "../../helpers/generel.helpers";

const addPlaylistToBackend = async (payload) =>
  axios
    .post(
      "v1/customer/playlist/",
      {
        name: payload?.formatedPlaylist.name,
        songs: payload?.formatedPlaylist.songs,
      },
      {
        headers: {
          authorization: await getToken(),
        },
      }
    )
    .then((result) => result?.data?.id);

const addScheduleToPlaylist = async (payload) =>
  axios.post(
    `v1/customer/playlist/${payload.playlistId}/schedule`,
    {
      schedule: [
        {
          is_shuffled: payload.isShuffled, // values is bool
          time: payload.time, // value is "00:01" string
          days: payload.days, //days [1,2,3]
        },
      ],
    },
    {
      headers: {
        authorization: await getToken(),
      },
    }
  );

const editScheduleOfPlaylist = async (payload) =>
  axios.patch(
    `v1/customer/playlist/${payload.playlistId}/schedule`,
    {
      schedule: [
        {
          is_shuffled: payload.isShuffled, // values is bool
          time: payload.time, // value is "00:01" string
          days: payload.days, //days [1,2,3]
        },
      ],
    },
    {
      headers: {
        authorization: await getToken(),
      },
    }
  );

const uploadPlaylistImage = async (payload) =>
  axios
    .post(`customer/playlists/${payload.playlistId}/image`, {
      headers: {
        authorization: await getToken(),
      },
    })
    .then((result) => result);

const addLikeToSongInPlaylist = async (payload) =>
  axios
    .post(`v1/customer/playlists/favorite/songs`, payload, {
      headers: {
        authorization: await getToken(),
      },
    })
    .catch((err) => err.response.data);

const unlikeSongInPlaylist = async (payload) =>
  axios
    .delete(
      `v1/customer/playlists/favorite/songs`,
      {
        song_id: payload.song_id,
      },
      {
        headers: {
          authorization: await getToken(),
        },
      }
    )
    .catch((err) => err.response.data);

export const playlistServices = {
  addPlaylistToBackend,
  addScheduleToPlaylist,
  editScheduleOfPlaylist,
  uploadPlaylistImage,
  addLikeToSongInPlaylist,
  unlikeSongInPlaylist,
};
