import { createSlice } from '@reduxjs/toolkit';
import { map } from 'lodash';
import { getFormattedSongs } from './musicplayer.helpers';

const INITIAL_STATE = {
  isLoading: false,
  error: null,
  songs: [],
  currentSongIndex: 0,
  isPlaying: false,
  currentTime: 0,
};

const musicPlayerSlice = createSlice({
  name: 'musicPlayer',
  initialState: INITIAL_STATE,
  reducers: {
    setSongs: (state, action) => {
      state.songs = getFormattedSongs(action.payload?.songs);
      state.currentSongIndex = action?.payload?.currentSongIndex || 0;
      state.isPlaying = true;
      state.isLoading = false;
    },
    setCurrentSongIndex: (state, action) => {
      state.currentSongIndex = action.payload;
      state.currentTime = 0;
    },
    setIsPlaying: (state, action) => {
      state.isPlaying = action.payload;
    },
    setCurrentTime: (state, action) => {
      state.currentTime = action.payload;
    },
    toggleLike: (state, action) => {
      const songId = action.payload?.id;
      const isLiked = action.payload?.isLiked;
      const updatedSongs = map(state.songs, (item) => {
        if (item.id === songId) {
          return {
            ...item,
            isLiked,
          };
        }
        return item;
      });
      return {
        ...state,
        songs: updatedSongs,
      };
    },
    toggleIgnore: (state, action) => {
      const songId = action.payload?.id;
      const isIgnored = action.payload?.isIgnored;
      const updatedSongs = map(state.songs, (item) => {
        if (item.id === songId) {
          return {
            ...item,
            isIgnored,
          };
        }
        return item;
      });
      return {
        ...state,
        songs: updatedSongs,
      };
    },
  },
});

export const {
  setSongs,
  setCurrentSongIndex,
  setIsPlaying,
  setCurrentTime,
  toggleLike,
  toggleIgnore,
} = musicPlayerSlice.actions;

const musicPlayerReducer = musicPlayerSlice.reducer;
export default musicPlayerReducer;
