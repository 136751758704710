import * as React from 'react';
const Volume = (props) => (
  <svg
    width={26}
    height={26}
    viewBox="0 0 26 26"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20.0847 4.76621C20.4021 4.44896 20.9165 4.44903 21.2338 4.76638C23.4171 6.9503 24.6435 9.91193 24.6435 13C24.6435 16.088 23.4171 19.0497 21.2338 21.2336C20.9165 21.5509 20.4021 21.551 20.0847 21.2338C19.7674 20.9165 19.7673 20.4021 20.0846 20.0847C21.9632 18.2055 23.0185 15.6572 23.0185 13C23.0185 10.3428 21.9632 7.79444 20.0846 5.91526C19.7673 5.59791 19.7674 5.08346 20.0847 4.76621ZM12.2687 4.68435C12.5502 4.81964 12.7292 5.10432 12.7292 5.41665V20.5833C12.7292 20.8957 12.5502 21.1803 12.2687 21.3156C11.9872 21.4509 11.653 21.4129 11.4091 21.2178L6.21501 17.0625H2.16669C1.71796 17.0625 1.35419 16.6987 1.35419 16.25V9.74999C1.35419 9.30126 1.71796 8.93749 2.16669 8.93749H6.21501L11.4091 4.7822C11.653 4.58709 11.9872 4.54905 12.2687 4.68435ZM11.1042 7.10716L7.00759 10.3844C6.86352 10.4997 6.68452 10.5625 6.50002 10.5625H2.97919V15.4375H6.50002C6.68452 15.4375 6.86352 15.5003 7.00759 15.6155L11.1042 18.8928V7.10716ZM16.2606 8.59038C16.5779 8.27312 17.0924 8.2732 17.4096 8.59055C18.5774 9.75869 19.2334 11.3428 19.2334 12.9946C19.2334 14.6463 18.5774 16.2305 17.4096 17.3986C17.0924 17.7159 16.5779 17.716 16.2606 17.3988C15.9432 17.0815 15.9432 16.5671 16.2604 16.2497C17.1236 15.3863 17.6084 14.2154 17.6084 12.9946C17.6084 11.7737 17.1236 10.6028 16.2604 9.73942C15.9432 9.42208 15.9432 8.90763 16.2606 8.59038Z"
      fill="white"
    />
  </svg>
);
export default Volume;
