import React, { useEffect, useState } from "react";
import propTypes from "prop-types";

import Button from "../../../components/button/Button";
import cx from "classnames";
import Icons from "../../../components/icons";
import ThumbNail from "../../../components/thumbNail/ThumbNail";
import Dropdown from "../../../components/dropdown/Dropdown";

import { ICONS } from "../../../constants/icons.constants";
import { useNavigate, useParams } from "react-router";
import { ROUTES } from "../../../constants/routes.constants";
import { filter, head, isEmpty, size } from "lodash";
import { useDispatch, useSelector } from "react-redux";
import {
  createSchedulePaylod,
  timeFormatConverter,
} from "./scheduleEditPage.helpers";
import { DAYS, HOURS, MINS, TIME_TYPE } from "./scheduleEditPage.constants";

import styles from "./scheduleEditPage.module.scss";
import { addSchedule } from "../../../redux/playlists/playlists.slice";

const ScheduleEditPage = (props) => {
  const { isNewSchedule } = props;

  const [isNameEdit, setIsNameEdit] = useState(false);
  const [values, setValues] = useState({});

  const dispatch = useDispatch();

  const navigate = useNavigate();

  const currentID = useParams()?.id;

  const Playlist = useSelector((state) => state.playlists.playlists);

  const currentPlaylist = head(
    filter(Playlist, (item) => item?.id === currentID)
  );

  const setSelectedPlaylist = (id) => {
    const hasId = filter(values.selectedPlaylists, (item) => item === id);

    if (isEmpty(hasId)) {
      setValues({
        ...values,
        selectedPlaylists: [...values.selectedPlaylists, id],
      });
    } else {
      const updatedPlaylist = filter(
        values.selectedPlaylists,
        (item) => item !== id
      );
      setValues({
        ...values,
        selectedPlaylists: updatedPlaylist,
      });
    }
  };

  const setSelectedPlayingDays = (id) => {
    const hasId = filter(values.slectedPlayingDays, (item) => item === id);

    if (isEmpty(hasId)) {
      setValues({
        ...values,
        slectedPlayingDays: [...values.slectedPlayingDays, id],
      });
    } else {
      const updatedPlaylist = filter(
        values.slectedPlayingDays,
        (item) => item !== id
      );
      setValues({
        ...values,
        slectedPlayingDays: updatedPlaylist,
      });
    }
  };

  const formatedTime = timeFormatConverter(
    currentPlaylist?.schedule?.[0]?.time
  );

  const handleBack = () => {
    navigate(ROUTES.MY_SCHEDULE);
  };

  const handleNameEdit = () => {
    setIsNameEdit(!isNameEdit);
  };

  const handleInputChange = (id) => (e) => {
    setValues({
      ...values,
      [id]: e.target.value,
    });
  };

  const handleSetPlayingHour = (id) => (e) => {
    setValues({
      ...values,
      selectedPlayingHours: {
        ...values.selectedPlayingHours,
        [id]: e.value,
      },
    });
  };

  const handleSaveClick = () => {
    if (isSaveBtnDisabled()) return;
    const { payload, ids } = createSchedulePaylod(values);
    dispatch(addSchedule({ schedule: payload, ids }));
    navigate(ROUTES.MY_SCHEDULE);
  };

  const isSaveBtnDisabled = () => {
    const { name, selectedPlaylists, slectedPlayingDays } = values;
    return (
      isEmpty(name) || isEmpty(selectedPlaylists) || isEmpty(slectedPlayingDays)
    );
  };

  useEffect(() => {
    setValues({
      name: currentPlaylist?.name || "My Schedule",
      totalSongs: 0,
      slectedPlayingDays: [],
      selectedPlaylists: [],
      selectedPlayingHours: {
        hours: 0,
        mins: 1,
        type: 2,
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={styles.pageContent}>
      <div className={styles.header}>
        <div>
          <div onClick={handleBack} className={styles.backIcon}>
            <Icons type={ICONS.BACK_ARROW} />
          </div>
          <p> {isNewSchedule ? "Create Schedule" : "My Schedule"} </p>
        </div>
      </div>

      <div className={styles.head}>
        <div className={styles.head2}>
          <ThumbNail
            name={values.name || "My Schedule"}
            className={styles.headImg}
          />

          <div className={styles.mainBox}>
            <div className={styles.box1}>
              {!isNameEdit ? (
                <p>{values.name}</p>
              ) : (
                <input
                  className={styles.nameInput}
                  value={values.name}
                  autoFocus={true}
                  onChange={handleInputChange("name")}
                  onBlur={handleNameEdit}
                />
              )}
              <div className={styles.editIcon} onClick={handleNameEdit}>
                <Icons type={ICONS.EDIT} />
              </div>
            </div>

            <div className={styles.box2}>
              <p className={styles.text1}>
                {size(currentPlaylist?.songs)} songs
              </p>
              <p className={styles.text1}>{formatedTime}</p>
            </div>
          </div>
        </div>
      </div>

      <div className={styles.form}>
        <div className={styles.box}>
          <p className={styles.mainText}>Playlist</p>
          <div className={styles.input}>
            <Dropdown
              placeholder="Select Playlist"
              playlistDropdown
              listWithCheckbox
              setSelectedPlaylist={setSelectedPlaylist}
            />
          </div>
        </div>
        <div className={styles.box}>
          <p className={styles.mainText}>Playing days</p>
          <div className={styles.input}>
            <Dropdown
              placeholder="Select playing days"
              arr={DAYS}
              playlistDaysDropdown
              setSelectedPlaylist={setSelectedPlayingDays}
              isHideIcon
            />
          </div>
        </div>
        <div className={styles.box}>
          <p className={styles.mainText}>Playing Hours</p>
          <div className={cx(styles.input, styles.dropdown)}>
            <Dropdown
              defaultValue={HOURS[0]}
              arr={HOURS}
              setValue={handleSetPlayingHour("hours")}
            />
          </div>
          <div className={cx(styles.input, styles.dropdown)}>
            <Dropdown
              defaultValue={MINS[0]}
              arr={MINS}
              setValue={handleSetPlayingHour("mins")}
            />
          </div>
          <div className={cx(styles.input, styles.dropdown)}>
            <Dropdown
              defaultValue={TIME_TYPE[0]}
              arr={TIME_TYPE}
              setValue={handleSetPlayingHour("type")}
            />
          </div>
        </div>

        <div className={styles.saveBtn}>
          <Button
            className={styles.btn}
            disabled={isSaveBtnDisabled()}
            onClick={handleSaveClick}
          >
            Save
          </Button>
        </div>
      </div>
    </div>
  );
};

ScheduleEditPage.propTypes = {
  isNewSchedule: propTypes.bool,
};

ScheduleEditPage.defaultProps = {
  isNewSchedule: false,
};

export default ScheduleEditPage;
