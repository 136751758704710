import { create } from "zustand";

import { EMPTY_USER, User } from "../types/user.types";

interface USER {
  user: User;
  isAuthenticated: boolean;
  isLoading: boolean;
  error: string;
  flushUserData: () => void;
  updateUser: (data: User) => void;
  setIsAuthenticated: (isAuthenticated: boolean) => void;
  setIsLoading: (isLoading: boolean) => void;
  setUser: (user: User) => void;
}

const userStore = create<USER>((set) => ({
  user: EMPTY_USER,
  isAuthenticated: false,
  isLoading: false,
  error: "",

  setIsAuthenticated: (isAuthenticated) => {
    set((state) => {
      return {
        ...state,
        isAuthenticated,
      };
    });
  },
  setIsLoading: (isLoading) => {
    set((state) => {
      return {
        ...state,
        isLoading,
      };
    });
  },
  setUser: (user) => {
    set((state) => {
      return {
        ...state,
        user,
      };
    });
  },
  updateUser: (data) => {
    set((state) => {
      return {
        ...state,
        user: {
          ...state.user,
          ...data,
        },
      };
    });
  },
  flushUserData: () => {
    set(() => {
      return {
        user: EMPTY_USER,
        isAuthenticated: false,
        isLoading: false,
        error: "",
      };
    });
  },
}));

export default userStore;
