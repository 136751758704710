import * as React from 'react';
const Repeat = (props) => (
  <svg
    width={26}
    height={26}
    viewBox="0 0 26 26"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.8732 16.5811L14.6338 15.7841C14.9021 15.5036 14.9021 15.051 14.6338 14.7705C14.3677 14.4901 13.9321 14.4901 13.6637 14.7705L11.2929 17.2478L13.6637 19.7251C13.9321 20.0031 14.3677 20.0031 14.6338 19.7251C14.9021 19.4446 14.9021 18.9895 14.6338 18.7091L13.8732 17.9145H16.6606C18.4664 17.9145 19.9331 16.3794 19.9331 14.4925V9.48635C19.9331 7.5995 18.4664 6.06689 16.6606 6.06689H9.34124C7.53316 6.06689 6.06641 7.5995 6.06641 9.48635V14.4925C6.06641 16.3794 7.53316 17.9145 9.34124 17.9145H9.99105V16.5811H9.34124C8.23708 16.5811 7.3425 15.6438 7.3425 14.4925V9.48635C7.3425 8.33505 8.23708 7.39782 9.34124 7.39782H12.9436H16.6606C17.7624 7.39782 18.6596 8.33505 18.6596 9.48635V14.4925C18.6596 15.6438 17.7624 16.5811 16.6606 16.5811H13.8732Z"
      fill="white"
    />
  </svg>
);
export default Repeat;
