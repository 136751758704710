import axios from "../axios";

const fetchSongs = (id) => axios.get(`/v1/customer/playlists/${id}/songs`);

const ignoreSong = (playlistId, songId, payload) =>
  axios.post(
    `/v1/customer/playlists/${playlistId}/songs/${songId}/ignore`,
    payload
  );

const setSchedule = (playlistId, payload) =>
  axios.patch(`/v1/customer/playlist/${playlistId}/schedule`, payload);

const addPlaylist = (payload) => axios.post("/v1/customer/playlist", payload);

const updatePlaylist = (payload) =>
  axios.patch(`/v1/customer/playlist`, payload);

const addImage = (playlistId, payload) =>
  axios.post(`/v1/customer/playlists/${playlistId}/image`, payload);

const deteleSchedule = (playlistId) =>
  axios.delete(`/v1/customer/playlist/${playlistId}/schedule`);

const updateSchedule = (playlistId, payload) =>
  axios.patch(`/v1/customer/playlist/${playlistId}/schedule`, payload);

export const playlistServices = {
  fetchSongs,
  ignoreSong,
  setSchedule,
  addPlaylist,
  updatePlaylist,
  addImage,
  deteleSchedule,
  updateSchedule,
};
