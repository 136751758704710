import React, { useCallback, useMemo, useState } from "react";

import propTypes from "prop-types";
import cx from "classnames";

import _noop from "lodash/noop";
import _get from "lodash/get";
import _isEmpty from "lodash/isEmpty";

import Icons from "../icons";

import styles from "./input.module.scss";

const Input = (props) => {
  const {
    type,
    placeholder,
    hasIcon,
    iconType,
    value,
    setValue,
    error,
    setError,
    id,
    validator,
    hasToggleIcon,
    icon1,
    icon2,
    ...rest
  } = props;

  const [isActive, setIsActive] = useState(false);
  const [isBlur, setIsBlur] = useState(false);
  const [toggleIcon, setToggleIcon] = useState(false);
  const errMessage = useMemo(() => _get(error, id, ""), [error, id]);

  const toggleIsActive = useCallback(() => {
    setError((prevState) => ({
      ...prevState,
      [id]: "",
    }));
    setIsActive((prevState) => !prevState);
  }, [id, setError]);

  const handleToggleIcon = () => {
    setToggleIcon((prevState) => !prevState);
  };

  const handleOnChange = useCallback(
    (e) => {
      setValue(e.target.value);
    },
    [setValue]
  );

  const onBlur = useCallback(() => {
    if (_isEmpty(value)) {
      setIsActive(false);
      return;
    }
    const errMessage = validator(value, id);
    setError((prevState) => ({
      ...prevState,
      [id]: errMessage,
    }));

    setIsBlur(true);
    toggleIsActive();
  }, [setError, id, value, validator, toggleIsActive]);

  return (
    <div>
      <div
        className={cx(styles.inputBox, {
          [styles.active]: isActive,
          [styles.blur]: isBlur,
          [styles.errorBox]: !_isEmpty(error[id]) && !isActive,
        })}
      >
        <input
          id={id}
          onFocus={toggleIsActive}
          onBlur={onBlur}
          placeholder={placeholder}
          value={value}
          onChange={handleOnChange}
          type={
            type === "password" ? (!toggleIcon ? "password" : "text") : "text"
          }
          {...rest}
        />
        {hasIcon && (
          <div className={styles.icon}>
            <Icons type={iconType} />
          </div>
        )}
        {hasToggleIcon && (
          <div className={styles.icon} onClick={handleToggleIcon}>
            <Icons type={toggleIcon ? icon1 : icon2} />
          </div>
        )}
      </div>
      {errMessage && <div className={styles.error}>{errMessage} </div>}
    </div>
  );
};

Input.propTypes = {
  type: propTypes.string,
  placeholder: propTypes.string,
  hasIcon: propTypes.bool,
  iconType: propTypes.string,
  value: propTypes.string,
  setValue: propTypes.func,
  error: propTypes.object,
  setError: propTypes.func,
  id: propTypes.string,
  validator: propTypes.func,
  hasToggleIcon: propTypes.bool,
  icon1: propTypes.string,
  icon2: propTypes.string,
};

Input.defaultProps = {
  type: "",
  placeholder: "",
  hasIcon: false,
  iconType: "",
  value: "",
  setValue: _noop,
  error: {},
  icon1: "",
  icon2: "",
  hasToggleIcon: false,
  setError: _noop,
  id: "",
  validator: _noop,
};

export default Input;
