import React from 'react';
import propTypes from 'prop-types';
import styles from './loadingSkeleton.module.scss';

const LoadingSkeleton = (props) => {
  const { isHomepage, isSchedule, isPlaylist, isPlaylistItem, isHomepageTop } =
    props;
  return (
    <>
      {isSchedule && (
        <div className={styles.container}>
          <div className={styles.imgBox}></div>
          <div className={styles.box}>
            <div className={styles.title}></div>
            <div className={styles.subTitle}></div>
          </div>
        </div>
      )}

      {isPlaylistItem && (
        <div className={styles.playlistItem}>
          <div className={styles.imgBox}></div>
          <div className={styles.box}>
            <div className={styles.title}></div>
          </div>
        </div>
      )}

      {isHomepage && (
        <div className={styles.homepage}>
          <div className={styles.imgBox}></div>
          <div className={styles.box}>
            <div className={styles.title}></div>
            <div className={styles.subTitle}></div>
          </div>
        </div>
      )}

      {isHomepageTop && (
        <div className={styles.homepageTop}>
          <div className={styles.imgBox}></div>
          <div className={styles.box}>
            <div className={styles.title}></div>
            <div className={styles.subTitle}></div>
            <div className={styles.musicBar}></div>
          </div>
        </div>
      )}

      {isPlaylist && (
        <div className={styles.playlist}>
          <div className={styles.title}></div>
        </div>
      )}
    </>
  );
};

LoadingSkeleton.propTypes = {
  style: propTypes.string,
  isHomepage: propTypes.bool,
  isPlaylist: propTypes.bool,
  isSchedule: propTypes.bool,
  isPlaylistItem: propTypes.bool,
  isHomepageTop: propTypes.bool,
};

LoadingSkeleton.defaultProps = {
  style: '',
  isHomepage: false,
  isPlaylist: false,
  isSchedule: false,
  isPlaylistItem: false,
  isHomepageTop: false,
};

export default LoadingSkeleton;
