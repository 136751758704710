import { createSlice } from "@reduxjs/toolkit";
import { map } from "lodash";

const INITIAL_STATE = {
  isLoading: false,
  songs: [],
  error: null,
  isAlreadyFetched: false,
};

const songsSlice = createSlice({
  name: "songs",
  initialState: INITIAL_STATE,
  reducers: {
    fetchSongsStart: (state) => {
      state.isLoading = true;
    },
    fetchSongsSuccess: (state, action) => {
      state.isLoading = false;
      state.songs = action.payload?.songs;
      state.isAlreadyFetched = true;
    },
    fetchSongsFailure: (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    },
    toggleLike: (state, action) => {
      const songId = action.payload?.id;
      const isLiked = action.payload?.isLiked;
      const updatedSongs = map(state.songs, (item) => {
        if (item.id === songId) {
          return {
            ...item,
            isLiked,
          };
        }
        return item;
      });
      return {
        ...state,
        songs: updatedSongs,
      };
    },
  },
});

export const {
  fetchSongsStart,
  fetchSongsSuccess,
  fetchSongsFailure,
  toggleLike,
} = songsSlice.actions;

const songsReducer = songsSlice.reducer;

export default songsReducer;
