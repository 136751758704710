import * as React from 'react';
const Home = (props) => (
  <svg
    width={26}
    height={26}
    viewBox="0 0 26 26"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M12.0073 4.69761C12.2755 4.46378 12.6306 4.3335 12.9997 4.3335C13.3687 4.3335 13.7238 4.46378 13.992 4.69761L20.9889 10.7971C21.4222 11.1741 21.6663 11.7003 21.6663 12.2491V19.6687C21.6663 20.1987 21.4381 20.7069 21.0317 21.0816C20.6254 21.4563 20.0743 21.6668 19.4997 21.6668H16.6108C16.3261 21.6668 16.0443 21.6151 15.7813 21.5146C15.5183 21.4141 15.2794 21.2668 15.0782 21.0811C14.877 20.8954 14.7174 20.675 14.6086 20.4324C14.4998 20.1899 14.4439 19.9299 14.4441 19.6674V16.3372C14.4441 16.1606 14.368 15.9912 14.2326 15.8663C14.0971 15.7414 13.9134 15.6712 13.7219 15.6712H12.2775C12.0859 15.6712 11.9022 15.7414 11.7668 15.8663C11.6313 15.9912 11.5552 16.1606 11.5552 16.3372V19.6674C11.5552 20.1973 11.327 20.7055 10.9206 21.0803C10.5143 21.455 9.9632 21.6655 9.38856 21.6655H6.49967C5.92504 21.6655 5.37394 21.455 4.96761 21.0803C4.56128 20.7055 4.33301 20.1973 4.33301 19.6674V12.2478C4.33301 11.699 4.57856 11.1728 5.0119 10.7958L12.0073 4.69494V4.69761ZM12.9997 5.66469L6.00279 11.7656C5.93175 11.8277 5.87515 11.9024 5.83642 11.9853C5.79768 12.0682 5.77762 12.1575 5.77745 12.2478V19.6674C5.77745 19.844 5.85354 20.0134 5.98899 20.1384C6.12443 20.2633 6.30813 20.3334 6.49967 20.3334H9.38856C9.58011 20.3334 9.76381 20.2633 9.89925 20.1384C10.0347 20.0134 10.1108 19.844 10.1108 19.6674V16.3372C10.1108 15.8073 10.3391 15.2991 10.7454 14.9243C11.1517 14.5496 11.7028 14.3391 12.2775 14.3391H13.7219C14.2965 14.3391 14.8476 14.5496 15.254 14.9243C15.6603 15.2991 15.8886 15.8073 15.8886 16.3372V19.6674C15.8886 19.844 15.9647 20.0134 16.1001 20.1384C16.2355 20.2633 16.4192 20.3334 16.6108 20.3334H19.4997C19.6912 20.3334 19.8749 20.2633 20.0104 20.1384C20.1458 20.0134 20.2219 19.844 20.2219 19.6674V12.2478C20.2219 12.1573 20.202 12.0677 20.1632 11.9846C20.1245 11.9015 20.0678 11.8265 19.9966 11.7642L12.9997 5.66469Z"
      fill="white"
    />
  </svg>
);
export default Home;
