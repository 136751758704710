import { get } from "lodash";
import { takeLatest, call, put, takeEvery } from "redux-saga/effects";

import { playlistServices } from "./playlists.service";
import {
  addPlaylistSuccess,
  addPlaylistFailure,
  addSchedule,
} from "../playlists/playlists.slice";

// 'eladtest3', '12345678'

function* addPlaylistRequestStart({ payload }) {
  try {
    const id = yield call(playlistServices.addPlaylistToBackend, payload);
    // // const customerId = get(response, "data.customerId",
    yield put(addPlaylistSuccess({ ...payload, id }));
  } catch (error) {
    yield put(addPlaylistFailure(error.message || "Something went wrong!"));
  }
}

function* addScheduleToPlaylistRequestStart({ payload }) {
  try {
    const response = yield call(
      playlistServices.addScheduleToPlaylist,
      payload
    );

    yield put(addSchedule(payload));
  } catch (err) {
    // console.log(err)
  }
}

function* uploadPlaylistImageRequestStart({ payload }) {
  try {
    const response = yield call(playlistServices.uploadPlaylistImage, payload);
    console.log(response, "saga run");
  } catch (err) {
    console.log(err, "error");
  }
}

function* addLikeToSongInPlaylistRequestStart({ payload }) {
  try {
    const response = yield call(
      playlistServices.addLikeToSongInPlaylist,
      payload
    );

    console.log(response, "likedd");
  } catch (err) {
    // console.log(err);
  }
}
function* unLikeTheSongInPlaylistRequestStart({ payload }) {
  try {
    const response = yield call(playlistServices.unlikeSongInPlaylist, payload);

    console.log(response, "unliked");
  } catch (err) {
    // console.log(err);
  }
}

const playlistSagas = [
  takeLatest("playlists/addPlaylistStart", addPlaylistRequestStart),
  takeLatest("playlists/addSchedule", addScheduleToPlaylistRequestStart),
  takeLatest("playlists/uploadPlaylistImage", uploadPlaylistImageRequestStart),
  takeLatest(
    "playlists/addLikeToSongInPlaylist",
    addLikeToSongInPlaylistRequestStart
  ),
  takeLatest(
    "playlists/unLikeTheSongInPlaylist",
    unLikeTheSongInPlaylistRequestStart
  ),
];

export default playlistSagas;
