import * as React from 'react';
const Mute = (props) => (
  <svg
    width={26}
    height={26}
    viewBox="0 0 26 26"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.2687 4.6842C12.5502 4.8195 12.7292 5.10418 12.7292 5.41651V20.5832C12.7292 20.8955 12.5502 21.1802 12.2687 21.3155C11.9872 21.4508 11.653 21.4127 11.4091 21.2176L6.21501 17.0623H2.16669C1.71796 17.0623 1.35419 16.6986 1.35419 16.2498V9.74984C1.35419 9.30111 1.71796 8.93734 2.16669 8.93734H6.21501L11.4091 4.78206C11.653 4.58695 11.9872 4.54891 12.2687 4.6842ZM11.1042 7.10702L7.00759 10.3843C6.86352 10.4996 6.68452 10.5623 6.50002 10.5623H2.97919V15.4373H6.50002C6.68452 15.4373 6.86352 15.5001 7.00759 15.6154L11.1042 18.8927V7.10702ZM17.8422 9.17532C18.1595 8.85802 18.6739 8.85802 18.9912 9.17532L21.6667 11.8508L24.3422 9.17532C24.6595 8.85802 25.1739 8.85802 25.4912 9.17532C25.8085 9.49262 25.8085 10.0071 25.4912 10.3244L22.8157 12.9998L25.4912 15.6753C25.8085 15.9926 25.8085 16.5071 25.4912 16.8244C25.1739 17.1417 24.6595 17.1417 24.3422 16.8244L21.6667 14.1489L18.9912 16.8244C18.6739 17.1417 18.1595 17.1417 17.8422 16.8244C17.5249 16.5071 17.5249 15.9926 17.8422 15.6753L20.5176 12.9998L17.8422 10.3244C17.5249 10.0071 17.5249 9.49262 17.8422 9.17532Z"
      fill="white"
    />
  </svg>
);
export default Mute;
