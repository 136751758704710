import * as React from 'react';
const EyeOff = (props) => (
  <svg
    width={26}
    height={26}
    viewBox="0 0 26 26"
    fill="#949494"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M23.9231 12.345C23.0305 10.4646 21.9683 8.93102 20.7365 7.74426L19.4447 9.03614C20.4981 10.0429 21.4157 11.3599 22.2092 13.0001C20.0967 17.3724 17.0981 19.4493 13 19.4493C11.7699 19.4493 10.6369 19.2599 9.60098 18.8811L8.2012 20.2809C9.64 20.9453 11.2396 21.2775 13 21.2775C17.8801 21.2775 21.5211 18.7359 23.9231 13.6527C24.0196 13.4482 24.0697 13.2249 24.0697 12.9989C24.0697 12.7728 24.0196 12.5495 23.9231 12.345ZM22.309 4.20379L21.2266 3.12012C21.2077 3.10123 21.1853 3.08625 21.1607 3.07603C21.136 3.06581 21.1096 3.06055 21.0829 3.06055C21.0562 3.06055 21.0298 3.06581 21.0051 3.07603C20.9804 3.08625 20.958 3.10123 20.9392 3.12012L18.1622 5.89582C16.6311 5.11379 14.9104 4.72278 13 4.72278C8.11995 4.72278 4.47893 7.26438 2.07698 12.3476C1.98043 12.552 1.93036 12.7753 1.93036 13.0014C1.93036 13.2275 1.98043 13.4508 2.07698 13.6552C3.03657 15.6763 4.19185 17.2963 5.5428 18.5152L2.85596 21.2013C2.8179 21.2394 2.79652 21.291 2.79652 21.3449C2.79652 21.3987 2.8179 21.4504 2.85596 21.4885L3.93989 22.5724C3.97798 22.6105 4.02962 22.6318 4.08347 22.6318C4.13732 22.6318 4.18897 22.6105 4.22706 22.5724L22.309 4.49121C22.3279 4.47235 22.3429 4.44995 22.3531 4.42529C22.3633 4.40063 22.3686 4.3742 22.3686 4.3475C22.3686 4.32081 22.3633 4.29438 22.3531 4.26972C22.3429 4.24506 22.3279 4.22266 22.309 4.20379ZM3.79084 13.0001C5.90588 8.62786 8.90452 6.5509 13 6.5509C14.3848 6.5509 15.644 6.78856 16.7863 7.27174L15.0013 9.0567C14.156 8.60567 13.1881 8.43828 12.2403 8.57922C11.2926 8.72016 10.4153 9.16197 9.73782 9.83947C9.06031 10.517 8.6185 11.3943 8.47756 12.342C8.33662 13.2897 8.50401 14.2576 8.95504 15.103L6.83696 17.2211C5.66467 16.1864 4.65413 14.7846 3.79084 13.0001ZM10.0547 13.0001C10.0552 12.5524 10.1612 12.1111 10.3643 11.712C10.5673 11.313 10.8616 10.9675 11.2233 10.7036C11.5849 10.4396 12.0038 10.2647 12.4457 10.1931C12.8877 10.1214 13.3403 10.1551 13.7668 10.2912L10.1895 13.8685C10.0999 13.5878 10.0544 13.2948 10.0547 13.0001Z"
      fill="white"
    />
    <path
      d="M12.8985 15.8439C12.8106 15.8439 12.724 15.8398 12.6382 15.8319L11.2971 17.1731C12.1037 17.482 12.9826 17.5506 13.8274 17.3707C14.6722 17.1908 15.4468 16.77 16.0576 16.1592C16.6684 15.5485 17.0891 14.7739 17.2691 13.929C17.449 13.0842 17.3803 12.2054 17.0714 11.3987L15.7303 12.7399C15.7382 12.8257 15.7422 12.9123 15.7422 13.0001C15.7424 13.3736 15.669 13.7435 15.5262 14.0886C15.3833 14.4337 15.1739 14.7473 14.9097 15.0114C14.6456 15.2755 14.3321 15.485 13.987 15.6278C13.6418 15.7707 13.272 15.8441 12.8985 15.8439Z"
      fill="white"
    />
  </svg>
);
export default EyeOff;
