import * as React from "react";
const IgnoreIcon = (props) => (
  <svg
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    width="100%"
    viewBox="0 0 225 225"
    enableBackground="new 0 0 225 225"
    xmlSpace="preserve"
    {...props}
  >
    <path
      fill="#FDFDFD"
      opacity={1}
      stroke="none"
      d=" M1.000000,84.000000  C1.000000,56.000004 1.000000,28.500010 1.000000,1.000011  C75.999977,1.000007 150.999954,1.000007 225.999954,1.000004  C225.999969,75.999969 225.999969,150.999939 225.999985,225.999939  C151.000046,225.999954 76.000092,225.999954 1.000068,225.999969  C1.000000,178.833328 1.000000,131.666672 1.000000,84.000000  M219.684052,143.061996  C224.206421,126.074982 225.192734,108.902191 221.409073,91.704346  C207.879486,30.208147 151.579391,-6.446662 89.923920,5.917002  C30.365810,17.860086 -8.904872,80.872101 6.511211,139.757385  C21.447815,196.811188 73.200310,231.623978 131.689667,221.804504  C176.448761,214.290115 205.488815,187.074310 219.684052,143.061996  z"
    />
    <path
      fill="#020202"
      opacity={1}
      stroke="none"
      d=" M219.516190,143.434830  C205.488815,187.074310 176.448761,214.290115 131.689667,221.804504  C73.200310,231.623978 21.447815,196.811188 6.511211,139.757385  C-8.904872,80.872101 30.365810,17.860086 89.923920,5.917002  C151.579391,-6.446662 207.879486,30.208147 221.409073,91.704346  C225.192734,108.902191 224.206421,126.074982 219.516190,143.434830  M178.552017,53.962238  C157.915787,32.415516 132.902176,22.884226 103.054039,25.959743  C54.089386,31.004999 17.766907,79.039818 26.286388,127.749733  C34.699020,175.848755 78.972908,208.447739 127.046112,200.623840  C160.854813,195.121490 184.744797,175.490448 196.372437,143.234268  C208.094330,110.716537 201.517334,80.815529 178.552017,53.962238  z"
    />
    <path
      fill="#FEFEFE"
      opacity={1}
      stroke="none"
      d=" M178.768936,54.242386  C201.517334,80.815529 208.094330,110.716537 196.372437,143.234268  C184.744797,175.490448 160.854813,195.121490 127.046112,200.623840  C78.972908,208.447739 34.699020,175.848755 26.286388,127.749733  C17.766907,79.039818 54.089386,31.004999 103.054039,25.959743  C132.902176,22.884226 157.915787,32.415516 178.768936,54.242386  M137.383865,114.116112  C120.916267,97.631920 104.469734,81.126602 87.971756,64.672859  C80.280640,57.002377 69.923164,56.490734 63.186577,63.324421  C56.547390,70.059303 56.972324,80.299728 64.423012,87.771500  C89.336113,112.755127 114.291473,137.696762 139.279633,162.605316  C146.750473,170.052353 156.991119,170.447861 163.714828,163.747284  C170.433670,157.051544 170.002518,146.820297 162.590591,139.336960  C154.392349,131.059723 146.122543,122.853333 137.383865,114.116112  z"
    />
    <path
      fill="#030303"
      opacity={1}
      stroke="none"
      d=" M137.633881,114.366104  C146.122543,122.853333 154.392349,131.059723 162.590591,139.336960  C170.002518,146.820297 170.433670,157.051544 163.714828,163.747284  C156.991119,170.447861 146.750473,170.052353 139.279633,162.605316  C114.291473,137.696762 89.336113,112.755127 64.423012,87.771500  C56.972324,80.299728 56.547390,70.059303 63.186577,63.324421  C69.923164,56.490734 80.280640,57.002377 87.971756,64.672859  C104.469734,81.126602 120.916267,97.631920 137.633881,114.366104  z"
    />
  </svg>
);
export default IgnoreIcon;
