import { reduce } from "lodash";
import { create } from "zustand";

import { Song } from "../types/song.types";

interface IgnoredSongsStore {
  songs: object;
  setSongs: (songs: string[]) => void;
  addSong: (songId: string) => void;
  deleteSong: (songId: string) => void;
}

const ignoredSongStore = create<IgnoredSongsStore>((set) => ({
  songs: new Set<Song>(),
  setSongs: (songIds: string[]) => {
    const objectToStore = reduce(
      songIds,
      (acc, songId) => ({
        ...acc,
        [songId]: true,
      }),
      {}
    );
    set({ songs: objectToStore });
  },
  addSong: (songId: string) => {
    set((state) => {
      const updatedObject = { ...state.songs, [songId]: true };
      return { songs: updatedObject };
    });
  },
  deleteSong: (songId: string) => {
    set((state) => {
      const updatedObject = { ...state.songs, [songId]: false };
      return { songs: updatedObject };
    });
  },
}));

export default ignoredSongStore;
