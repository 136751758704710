import { filter } from "lodash";
import { create } from "zustand";

import { Song } from "../types/song.types";
import { getFormattedSong } from "./general.helper";

interface MusicPlayer {
  isLoading: boolean;
  error: string;
  songs: Song[];
  currentSongIndex: number;
  playList: any;
  isShuffle: boolean;
  searchedSong: Song;
  toggleIsShuffle: (val: boolean) => void;
  setPlayList: (playList: any) => void;
  setSongs: (songs: Song[], songIndex?: number) => void;
  setCurrentSongIndex: (index: number) => void;
  playSearchSong: (song: Song) => void;
  isPlaying: boolean;
  setIsPlaying: (isPlaying: boolean) => void;
  aoudioRef1: any;
  setAudioRef1: (ref: object) => void;
  audioRef2: any;
  setAudioRef2: (ref: object) => void;
  currentAudioRefIndex: any;
  setCurrentAudioRefIndex: (index: object) => void;
  setPlayListFromScoket: (
    playList: any,
    songs: Song[],
    songIndex: number
  ) => void;
  beforeShuffleSongs: Song[];
  setBeforeShuffleSongs: (songs: Song[]) => void;
}

const musicPlayerStore = create<MusicPlayer>((set) => ({
  isLoading: false,
  error: "",
  songs: [],
  currentSongIndex: 0,
  searchedSong: null,
  playList: null,
  isPlaying: false,
  aoudioRef1: null,
  audioRef2: null,
  currentAudioRefIndex: null,
  isShuffle: false,
  beforeShuffleSongs: [],

  setBeforeShuffleSongs: (songs: Song[]) => {
    set({ beforeShuffleSongs: songs });
  },

  toggleIsShuffle: (val) => {
    set((state) => {
      return {
        ...state,
        isShuffle: val,
      };
    });
  },

  setCurrentAudioRefIndex: (index: object) => {
    set({ currentAudioRefIndex: index });
  },

  setAudioRef1: (ref: object) => {
    set({ aoudioRef1: ref });
  },
  setAudioRef2: (ref: object) => {
    set({ audioRef2: ref });
  },

  setIsPlaying: (isPlaying: boolean) => {
    set({ isPlaying });
  },

  setSongs: (songs: Song[], songIndex = 0) => {
    // const updatedSongs = filter(songs, (song) => !song.ignore);
    // set({ songs: updatedSongs, currentSongIndex: songIndex });
    set({ songs, currentSongIndex: songIndex });
  },
  setCurrentSongIndex: (index: number) => {
    console.log("from zustand", index);
    set({ currentSongIndex: index, searchedSong: null });
  },

  // this is for serach bar --> replacking the array with selcted song
  playSearchSong: (song: Song) => {
    set((state) => {
      const formattedSong = getFormattedSong(song);
      return {
        ...state,
        searchedSong: formattedSong,
      };
    });
  },
  setPlayList: (playList: any) => {
    set({ playList, searchedSong: null });
  },

  setPlayListFromScoket: (playList: any, songs, songIndex) => {
    const updatedSongs = filter(songs, (song) => !song.ignore);
    set({
      playList,
      // isShuffle: false,
      searchedSong: null,
      songs: updatedSongs,
      currentSongIndex: songIndex,
    });
  },
}));

export default musicPlayerStore;
