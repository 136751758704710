import axios from "../axios";

const fetchSongs = (searchKey = "") =>
  axios.get("/v1/customer/songs", { params: { search: searchKey } });

const addSongToFavorites = (payload) =>
  axios.post(`/v1/customer/playlists/favorite/songs`, payload);

const removeSongFromFavorites = (payload) =>
  axios.delete(`/v1/customer/playlists/favorite/songs`, { data: payload });

const fetchLikedSongs = () => axios.get("/v1/customer/playlists/favorite");

export const songsServices = {
  fetchSongs,
  addSongToFavorites,
  removeSongFromFavorites,
  fetchLikedSongs,
};
