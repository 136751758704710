import React from "react";
import cx from "classnames";
import PropTypes from "prop-types";

import styles from "./header.module.scss";
import { map } from "lodash";

const Header = (props) => {
  const { columns, fromScreen } = props;

  return (
    <div className={styles.table}>
      <div className={cx(styles.rowContainer, styles.headerContainer)}>
        <div className={styles.thumbnailContainer} />
        <div className={styles.columnsContainer}>
          {fromScreen !== "homePage" ? (
            <>
              {map(columns, (column, index) => {
                return (
                  <p key={index} className={styles.column}>
                    {column}
                  </p>
                );
              })}
            </>
          ) : (
            <>
              <p className={styles.column}>Song</p>
              <p className={styles.column}>Artist</p>
              <div className={styles.columnLast}>
                <p className={styles.column}>Like</p>
                <p className={styles.column}>Ignore</p>
              </div>
            </>
          )}

          {/* <p className={styles.column}>Song</p>
          <p className={styles.column}>Artist</p>
          <p className={styles.column}>Like</p> */}
        </div>
      </div>
    </div>
  );
};

Header.propTypes = {
  columns: PropTypes.array,
};

Header.defaultProps = {
  columns: [],
};

export default Header;
