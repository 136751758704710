import * as React from 'react';
const EyeOn = (props) => (
  <svg
    width={26}
    height={26}
    viewBox="0 0 26 26"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M23.0282 11.8753C23.5417 12.547 23.5417 13.4538 23.0282 14.1243C21.4108 16.2358 17.5303 20.5832 12.9998 20.5832C8.46931 20.5832 4.58881 16.2358 2.97139 14.1243C2.72156 13.8027 2.58594 13.4071 2.58594 12.9998C2.58594 12.5926 2.72156 12.1969 2.97139 11.8753C4.58881 9.76392 8.46931 5.4165 12.9998 5.4165C17.5303 5.4165 21.4108 9.76392 23.0282 11.8753Z"
      stroke="white"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12.9998 16.2498C14.7947 16.2498 16.2498 14.7948 16.2498 12.9998C16.2498 11.2049 14.7947 9.74984 12.9998 9.74984C11.2049 9.74984 9.74981 11.2049 9.74981 12.9998C9.74981 14.7948 11.2049 16.2498 12.9998 16.2498Z"
      stroke="white"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default EyeOn;
