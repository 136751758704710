import axios from "../../axios";
import { getToken } from "../../helpers/generel.helpers";

const fetchSongs = () =>
  axios.get("v1/customer/songs", {
    headers: {
      authorization: getToken(),
    },
  });

const addIgnoreToSongToBackend = async (payload) => {
  // console.log(payload, "payy");
  return axios
    .post(
      `v1/customer/playlists/${payload?.playlistId}/songs/${payload?.songId}/ignore`,
      JSON.stringify({
        ignore: payload?.ignore,
      }),
      {
        headers: {
          authorization: getToken(),
        },
      }
    )
    .catch((err) => err.response.data);
};

export const songsServices = {
  fetchSongs,
  addIgnoreToSongToBackend,
};
