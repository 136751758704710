import { get } from "lodash";
import { takeLatest, call, put } from "redux-saga/effects";
import {
  getRefreshToken,
  getToken,
  LOCAL_STORAGE,
} from "../../helpers/generel.helpers";

import { fetchPlayListsSuccess } from "../playlists/playlists.slice";
import { userService } from "./user.service";
import { loginFailure, loginSuccess } from "./user.slice";

// 'eladtest3', '12345678'

function* loginRequestStart({ payload }) {
  try {
    const token = getToken();

    if (!token) {
      const { username, password } = payload;
      const response = yield call(userService.loginUser, username, password);
      const status = get(response, "data.status", 2);
      // const customerId = get(response, "data.customerId", "");
      const accessToken = get(response, "data.tokens.accessToken", "");
      const refreshToken = get(response, "data.tokens.refreshToken", "");

      if (status === 2) {
        yield put(loginFailure("Wrong username or password"));
        return;
      }

      const JSON_STRING_LOCAL_STORAGE = JSON.stringify({
        accessToken,
        refreshToken,
      });
      localStorage.setItem(LOCAL_STORAGE.TOKEN, JSON_STRING_LOCAL_STORAGE);
    } else {
      const newToken = yield call(userService.getNewTokenFromRefreshToken);

      const refreshToken = getRefreshToken();

      const JSON_STRING_LOCAL_STORAGE = JSON.stringify({
        accessToken: newToken,
        refreshToken,
      });

      localStorage.setItem(LOCAL_STORAGE.TOKEN, JSON_STRING_LOCAL_STORAGE);
    }

    const customerDataResponse = yield call(userService.fetchCustomerData);

    const { data } = customerDataResponse || {};
    const { playlists } = data || {};

    yield put(
      loginSuccess({
        ...data,
      })
    );
    yield put(fetchPlayListsSuccess({ playlists }));
  } catch (error) {
    yield put(loginFailure(error.message || "Something went wrong!"));
  }
}

function* logoutRequestStart() {
  try {
    yield localStorage.clear(LOCAL_STORAGE.TOKEN);
  } catch (err) {
    console.log(err);
  }
}

const userSagas = [
  takeLatest("user/loginStart", loginRequestStart),
  takeLatest("user/logoutAction", logoutRequestStart),
];

export default userSagas;
