import React from "react";

import { compact, isEmpty, map, set } from "lodash";
import { useNavigate } from "react-router";

import NillDisplaySchedule from "../../components/nillDisplaySchedule/NillDisplaySchedule";
import LoadingSkeleton from "../../components/loadingSkeleton/LoadingSkeleton";

import { ICONS } from "../../constants/icons.constants";
import { ROUTES } from "../../constants/routes.constants";

import playlistStore from "../../zustand/playlist.store";

import styles from "./schedule.module.scss";
import ScheduleListItem from "../../components/scheduleListItem";
import Button from "../../components/button/Button";
import CreateSchedulePopUp from "../../components/createSchedulePopUp";
import ScheduleToPlaySongPopUp from "../../components/scheduleToPlaySongPopUp";

const Schedule = () => {
  const { playlists: playList, isLoading } = playlistStore();

  const [isModalVisible, setIsModalVisible] = React.useState(false);
  const [isVisible, setIsVisible] = React.useState(false);
  const [selectedPlaylist, setSelectedPlaylist] = React.useState(null);

  const navigate = useNavigate();

  const handleNewSchedule = () => {
    navigate(ROUTES.MY_PLAYLIST);
  };

  const schedules = compact(map(playList, (item) => item.schedule));

  const onBtnClick = () => {
    setIsModalVisible(true);
  };
  const handleSuccess = (selectedPlaylist) => {
    setSelectedPlaylist(selectedPlaylist);
    setIsModalVisible(false);

    setIsVisible(true);
  };

  return (
    <div className={styles.page}>
      <div className={styles.header}>
        <p>My Schedules</p>
        <Button onClick={onBtnClick} className={styles.btn}>
          Create Schedule
        </Button>
      </div>

      <div className={styles.container}>
        {isLoading ? (
          <>
            <LoadingSkeleton isSchedule />
            <LoadingSkeleton isSchedule />
            <LoadingSkeleton isSchedule />
            <LoadingSkeleton isSchedule />
            <LoadingSkeleton isSchedule />
          </>
        ) : isEmpty(schedules) ? (
          <NillDisplaySchedule
            head="You do not have any schedules you can create one for you here from playlist page"
            btnPlaceholder="Create Schedule"
            iconType={ICONS.MY_SCHEDULE}
            onBtnClick={handleNewSchedule}
          />
        ) : (
          map(playList, (item1) => {
            if (item1.schedule !== null) {
              return map(item1.schedule, (item2, sIndex) => (
                <ScheduleListItem
                  id={item1?.id}
                  scheduleListView
                  scheduleName={item1?.name}
                  totalHours={item2?.time}
                  days={item2.days}
                  key={item2?.id + item1?.id}
                  schduleIndex={sIndex}
                  schedules={item1.schedule}
                  imageUrl={item1?.image}
                  color={item1.color}
                />
              ));
            }
            return <div key={item1?.id} />;
          })
        )}
      </div>
      <CreateSchedulePopUp
        isOpen={isModalVisible}
        setIsOpen={setIsModalVisible}
        iconType={ICONS.RED_DELETE}
        btnPlaceholder="Continue"
        onCancle={() => setIsModalVisible(false)}
        isLoading={isLoading}
        handleSuccess={handleSuccess}
      />
      {isVisible && selectedPlaylist ? (
        <ScheduleToPlaySongPopUp
          playlistId={selectedPlaylist}
          setIsVisible={setIsVisible}
          from="schedule"
        />
      ) : null}
    </div>
  );
};

export default Schedule;
