import {
  isEmptyError,
  validateEmptyField,
} from '../../helpers/generel.helpers';
import { ERROR_FIELDS } from './login.constants';

export const checkIfCanMove = (username, password) => {
  const errors = {
    [ERROR_FIELDS.USER_NAME]: validateEmptyField(
      username,
      ERROR_FIELDS.USER_NAME,
    ),
    [ERROR_FIELDS.PASSWORD]: validateEmptyField(
      password,
      ERROR_FIELDS.PASSWORD,
    ),
  };
  return {
    errors,
    canMove: isEmptyError(errors),
  };
};
