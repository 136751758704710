import React from "react";
import LoadingSkeleton from "../../components/loadingSkeleton/LoadingSkeleton";

const Loader = () => {
  return (
    <>
      <LoadingSkeleton isPlaylistItem />
      <LoadingSkeleton isPlaylistItem />
      <LoadingSkeleton isPlaylistItem />
      <LoadingSkeleton isPlaylistItem />
      <LoadingSkeleton isPlaylistItem />
    </>
  );
};

export default Loader;
