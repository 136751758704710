import React, { useState } from "react";
import PopUp from "../popUp";
import cx from "classnames";
import propTypes from "prop-types";
import { ICONS } from "../../constants/icons.constants";
import { useNavigate } from "react-router-dom";

import styles from "./scheduleListPopUp.module.scss";
import { noop } from "lodash";
import Icons from "../icons";

const ScheduleListPopUp = ({
  visible,
  setVisible,
  id,
  btnPlace1,
  isPlaylist,
  style,
  onDeleteSuccess,
  onEditSuccess,
}) => {
  const [popUp, setPopUp] = useState(false);

  const navigate = useNavigate();

  const handleDelete = (e) => {
    e.stopPropagation();
    onDeleteSuccess();
    setPopUp((prevState) => !prevState);
    setVisible(false);
  };

  const handelEdit = () => {
    onEditSuccess(id);
    navigate(`/schedule/${id}/edit`);
  };

  const handleBackdrop = (e) => {
    e.stopPropagation();
    setVisible((prevState) => !prevState);
  };

  return (
    <>
      {visible && (
        <>
          <div className={styles.backDrop} onClick={handleBackdrop}></div>
          <div className={cx(styles.editBookingListBox, style)}>
            <div className={styles.box}>
              {isPlaylist && (
                <>
                  <div className={styles.childBox} onClick={handelEdit}>
                    <Icons type={ICONS.EDIT} />
                    <p>Edit</p>
                  </div>

                  <div className={styles.childBox} onClick={handleDelete}>
                    <Icons type={ICONS.DELETE} />
                    <p>{btnPlace1}</p>
                  </div>
                </>
              )}

              {!isPlaylist && (
                <div className={styles.childBox} onClick={handleDelete}>
                  <Icons type={ICONS.DELETE} />
                  <p>{btnPlace1}</p>
                </div>
              )}
            </div>
          </div>
        </>
      )}
      <PopUp
        isOpen={popUp}
        setIsOpen={setPopUp}
        title="Are you sure, 
      you want to delete this schedule ?"
        iconType={ICONS.RED_DELETE}
        btnPlaceholder="Delete"
        onSuccess={onDeleteSuccess}
        id={id}
      />
    </>
  );
};

ScheduleListPopUp.propTypes = {
  visible: propTypes.bool.isRequired,
  setVisible: propTypes.func.isRequired,
  id: propTypes.string,
  btnPlace1: propTypes.string.isRequired,
  isPlaylist: propTypes.bool,
  style: propTypes.string,
  onDeleteSuccess: propTypes.func,
  onEditSuccess: propTypes.func,
};

ScheduleListPopUp.defaultProps = {
  id: "",
  isPlaylist: false,
  style: "",
  onDeleteSuccess: noop,
  onEditSuccess: noop,
};

export default ScheduleListPopUp;
