import * as React from "react";
const IgnoreSong = (props) => (
  <svg
    width={38}
    height={38}
    viewBox="0 0 38 38"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M19 10.75C14.4437 10.75 10.75 14.4437 10.75 19C10.75 23.5563 14.4437 27.25 19 27.25C23.5563 27.25 27.25 23.5563 27.25 19C27.25 14.4437 23.5563 10.75 19 10.75ZM9.25 19C9.25 13.6152 13.6152 9.25 19 9.25C24.3848 9.25 28.75 13.6152 28.75 19C28.75 24.3848 24.3848 28.75 19 28.75C13.6152 28.75 9.25 24.3848 9.25 19ZM14.8697 14.8697C15.1626 14.5768 15.6374 14.5768 15.9303 14.8697L23.5671 22.5064C23.86 22.7993 23.86 23.2742 23.5671 23.5671C23.2742 23.86 22.7993 23.86 22.5064 23.5671L14.8697 15.9303C14.5768 15.6374 14.5768 15.1626 14.8697 14.8697Z"
      fill="#949494"
    />
  </svg>
);
export default IgnoreSong;
