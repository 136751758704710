import { map, round } from "lodash";
import { MEDIA_URL } from "../../axios";

const alreadyPreloaded = new Set();

export const preloadTrack = (src) => {
  if (typeof src === "string" && !alreadyPreloaded.has(src)) {
    alreadyPreloaded.add(src);
    const head = document.getElementsByTagName("HEAD")[0];
    const link = document.createElement("link");
    link.rel = "prefetch";
    link.href = src;
    head.appendChild(link);
  }
};

export const getFormattedTime = (s) => {
  if (!s) return "0:00";
  if (s === Infinity) return "";
  const number = round(parseInt(s), 2);
  const minutes = parseInt(number / 60);
  const seconds = parseInt(number - minutes * 60);
  if (seconds < 10) return `${minutes}:0${seconds}`;
  return `${minutes}:${seconds}`;
};

export const getFormattedSongs = (songs) =>
  map(songs, (item) => `${MEDIA_URL}/songs/${item.fileName}`);
