import { takeLatest, put, call } from "redux-saga/effects";
import { map } from "lodash";
import { setSongs } from "../musicplayer/musicplayer.slice";
import { playlistServices } from "../playlists/playlists.service";
import { songsServices } from "./songs.service";

import { fetchSongsFailure, fetchSongsSuccess } from "./songs.slice";
import {
  addIgnoreToSongFailure,
  addIgnoreToSongSuccess,
} from "../playlists/playlists.slice";

export function* fetchSongsRequestStart({ payload }) {
  try {
    const response = yield call(songsServices.fetchSongs);
    const { data } = response || {};
    yield put(fetchSongsSuccess({ songs: data }));
    yield put(setSongs({ songs: data }));
  } catch (error) {
    yield put(fetchSongsFailure(error.message));
  }
}

function* addIgnoreToSongRequestStart({ payload }) {
  try {
    const res = yield call(songsServices.addIgnoreToSongToBackend, payload);
    // console.log(res, "catntt");

    yield put(addIgnoreToSongSuccess(payload));
  } catch (err) {
    yield put(addIgnoreToSongFailure(err.message || "Something went Wrong"));
  }
}

const songsSagas = [
  takeLatest("songs/fetchSongsStart", fetchSongsRequestStart),
  takeLatest("playlists/addIgnoreToSongStart", addIgnoreToSongRequestStart),
];

export default songsSagas;
