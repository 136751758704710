import { create } from "zustand";

import { EMPTY_PLAYLIST, Playlist } from "../types/playlist.types";
import { getFormattedPlaylist, getFormattedPlaylists } from "./general.helper";
import { Song } from "../types/song.types";

interface USER {
  isLoading: boolean;
  playlists: Playlist[];
  error: null;
  currentPlayList: Playlist;
  currentSongIndex: number;
  currentPlaylistId: string;
  setPlaylists: (playlists: Playlist[]) => void;
  setIsLoading: (isLoading: boolean) => void;
  addNewPlaylist: (playlist: Playlist) => void;
  updatePlaylist: (playlist: Playlist, id: string) => void;
  setIgnoreSong: (id: string, playlistId: string, ignore: boolean) => void;
  setCurrentPlaylistId: (id: string) => void;
  addLikeToSong: (id: string, playlistId: string, likedStatus: boolean) => void;
  updateSchedule: (schedule: any, id: string) => void;
  addLikedSongToPlaylist: (song: Song) => void;
  removeUnLikedSongFromPlaylist: (song: Song) => void;
}

const playlistStore = create<USER>((set) => ({
  isLoading: false,
  playlists: [],
  error: null,
  currentPlayList: EMPTY_PLAYLIST,
  currentSongIndex: 0,
  currentPlaylistId: "",

  setPlaylists: (playlists) => {
    set((state) => {
      return {
        ...state,
        playlists: getFormattedPlaylists(playlists),
      };
    });
  },
  setIsLoading: (isLoading) => {
    set((state) => {
      return {
        ...state,
        isLoading,
      };
    });
  },
  addNewPlaylist: (playlist) => {
    set((state) => {
      return {
        ...state,
        playlists: [getFormattedPlaylist(playlist), ...state.playlists],
      };
    });
  },
  updatePlaylist: (data, id) => {
    set((state) => {
      const newPlaylists = state.playlists.map((p) => {
        if (p.id === id) {
          return {
            ...p,
            ...data,
          };
        }
        return p;
      });
      return {
        ...state,
        playlists: newPlaylists,
      };
    });
  },

  setIgnoreSong: (id, playlistId, ignore) => {
    set((state) => {
      const newPlaylists = state.playlists.map((p) => {
        if (p.id === playlistId) {
          const newSongs = p.songs.map((s) => {
            if (s.id === id) {
              return {
                ...s,
                ignore,
              };
            }
            return s;
          });
          return {
            ...p,
            songs: newSongs,
          };
        }
        return p;
      });
      return {
        ...state,
        playlists: newPlaylists,
      };
    });
  },
  setCurrentPlaylistId: (id) => {
    set((state) => {
      const currentPlaylist = state.playlists.find((p) => p.id === id);
      return {
        ...state,
        currentPlayList: currentPlaylist || EMPTY_PLAYLIST,
        currentPlaylistId: id,
      };
    });
  },
  addLikeToSong: (id, playlistId, likedStatus) => {
    set((state) => {
      const newPlaylists = state.playlists.map((p) => {
        if (p.id === playlistId) {
          const newSongs = p.songs.map((s) => {
            if (s.id === id) {
              return {
                ...s,
                favorite: likedStatus,
              };
            }
            return s;
          });
          return {
            ...p,
            songs: newSongs,
          };
        }
        return p;
      });
      return {
        ...state,
        playlists: newPlaylists,
      };
    });
  },

  addLikedSongToPlaylist: (song) => {
    set((state) => {
      const newPlaylists = state.playlists.map((p) => {
        if (p.is_favorite) {
          const exist = p.songs.find((s) => s.id === song.id);
          if (exist) {
            return p;
          }

          return {
            ...p,
            songs: [song, ...p.songs],
          };
        }
        return p;
      });
      return {
        ...state,
        playlists: newPlaylists,
      };
    });
  },

  removeUnLikedSongFromPlaylist: (song) => {
    set((state) => {
      const newPlaylists = state.playlists.map((p) => {
        if (p.is_favorite) {
          const newSongs = p.songs.filter((s) => s.id !== song.id);
          return {
            ...p,
            songs: newSongs,
          };
        }
        return p;
      });
      return {
        ...state,
        playlists: newPlaylists,
      };
    });
  },

  updateSchedule: (schedule, id) => {
    set((state) => {
      const newPlaylists = state.playlists.map((p) => {
        if (p.id === id) {
          return {
            ...p,
            schedule,
          };
        }
        return p;
      });
      return {
        ...state,
        playlists: newPlaylists,
      };
    });
  },
}));

export default playlistStore;
