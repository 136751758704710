const getHours = () => {
  var arr = [];
  for (let index = 0; index <= 12; index++) {
    // if (index === 0) {
    //   arr.push({
    //     id: index,
    //     value: index,
    //     label: `12`,
    //   });
    // } else
    if (index <= 9) {
      arr.push({
        id: index,
        value: index,
        label: `0${index}`,
      });
    } else {
      arr.push({
        id: index,
        value: index,
        label: `${index}`,
      });
    }
  }

  return arr;
};

const getMinutes = () => {
  var arr = [];
  for (let index = 0; index <= 60; index++) {
    if (index <= 9) {
      arr.push({
        id: index,
        value: index,
        label: `0${index}`,
      });
    } else {
      arr.push({
        id: index,
        value: index,
        label: `${index}`,
      });
    }
  }

  return arr;
};

export const HOURS = getHours();
export const MINS = getMinutes();
export const TIME_TYPE = [
  {
    id: 1,
    value: 1,
    label: "AM",
  },
  {
    id: 2,
    value: 2,
    label: "PM",
  },
];
export const DAYS = [
  {
    id: 1,
    value: 1,
    label: "Monday",
  },
  {
    id: 2,
    value: 2,
    label: "Tuesday",
  },
  {
    id: 3,
    value: 3,
    label: "Wednesday",
  },
  {
    id: 4,
    value: 4,
    label: "Thursday",
  },
  {
    id: 5,
    value: 5,
    label: "Friday",
  },
  {
    id: 6,
    value: 6,
    label: "Saturday",
  },
  {
    id: 0,
    value: 0,
    label: "Sunday",
  },
];
