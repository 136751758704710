import React, { memo } from "react";
import cx from "classnames";
import propTypes from "prop-types";
import { isEmpty, noop } from "lodash";

import styles from "./songList.module.scss";
import ThumbNail from "../thumbNail/ThumbNail";
import Icons from "../icons";
import { ICONS } from "../../constants/icons.constants";
import ScheduleToPlaySongPopUp from "../scheduleToPlaySongPopUp/ScheduleToPlaySongPopUp";
import { MEDIA_URL } from "../../axios";

const SongList = (props) => {
  const {
    songName,
    artist,
    backgroundChange,
    color,
    thumbNailClassName,
    onClick,
    image,
    visibelePhoto,
    isActive,
    iconType,
    iconContainerClassName,
    onIconClick,
    isVisible,
    setIsVisible,
    id,
    currentPlaylistId,
    songObj,
  } = props;

  const handleIconClick = (e) => {
    e.stopPropagation();
    onIconClick(songObj, isActive);
  };

  const renderIcon = () => {
    return (
      <div
        className={cx(styles.iconContainer, iconContainerClassName, {
          [styles.active]: isActive,
        })}
        onClick={handleIconClick}
      >
        <Icons type={iconType} />
      </div>
    );
  };

  return (
    <div
      className={cx(styles.container, {
        [styles.backgroundChange]: backgroundChange,
      })}
      onClick={onClick}
    >
      <div className={styles.thumbNailContainer}>
        <ThumbNail
          name={songName}
          color={color}
          className={cx(styles.thumbNail, thumbNailClassName)}
          hasImage={!isEmpty(image)}
          imgSrc={`${MEDIA_URL}/playlist_images/${image}`}
          photoString={visibelePhoto}
          imageFileName={image}
        />
      </div>
      <div className={styles.extraInfo}>
        <div className={cx(styles.column, styles.text1)}>
          {songName}
          {/* tooltip */}
          <div className={cx(styles.customTooltip)}>{songName}</div>
        </div>
        <p className={cx(styles.column, styles.text2)}>{artist}</p>
        <div className={cx(styles.column)}>{renderIcon()}</div>
      </div>
      {isVisible && currentPlaylistId === id ? (
        <ScheduleToPlaySongPopUp playlistId={id} setIsVisible={setIsVisible} />
      ) : null}
    </div>
  );
};

SongList.propTypes = {
  songName: propTypes.string,
  artist: propTypes.oneOfType([propTypes.string, propTypes.number]),
  backgroundChange: propTypes.bool,
  color: propTypes.string,
  thumbNailClassName: propTypes.string,
  onClick: propTypes.func,
  image: propTypes.string,
  visibelePhoto: propTypes.string,
  isActive: propTypes.bool,
  iconType: propTypes.oneOf(Object.values(ICONS)),
  iconContainerClassName: propTypes.string,
  onIconClick: propTypes.func,
};

SongList.defaultProps = {
  songName: "",
  artist: "-",
  backgroundChange: false,
  color: "",
  thumbNailClassName: "",
  onClick: noop,
  image: "",
  visibelePhoto: "",
  isActive: false,
  iconType: ICONS.LIKE_SONG_LIST,
  iconContainerClassName: "",
  onIconClick: noop,
};

export default memo(SongList);
