import React, { useState } from "react";

import Button from "../button";
import propTypes from "prop-types";
import cx from "classnames";
import Icons from "../icons";
import { noop } from "lodash";

import styles from "./PopUp.module.scss";
import { BUTTON_TYPES } from "../button/Button";

const PopUp = ({
  isOpen,
  setIsOpen,
  title,
  iconType,
  onSuccess,
  btnPlaceholder,
  onCancle,
  isLoading,
}) => {
  // const [isLoading, setIsLoading] = useState(false);

  const onBackgroundClick = (e) => {
    if (e) e.stopPropagation();
    setIsOpen(false);
  };

  const onCancleClick = () => {
    onCancle();
    onBackgroundClick();
  };

  const handleSuccess = async (e) => {
    e.stopPropagation();
    onSuccess();
    // setIsLoading(false);
    // setIsOpen(false);
  };

  return (
    <div className={cx([styles.popUpWrapper], { [styles.popUpOpen]: isOpen })}>
      <div className={styles.popUpBg} onClick={onBackgroundClick}></div>
      <div
        className={styles.popUpChildren}
        onClick={(e) => e.stopPropagation()}
      >
        <div className={styles.deleteEventPopUp}>
          <div
            className={cx([styles.iconBox], {
              [styles.redBg]: iconType === "RED_DELETE" || "ERROR",
            })}
          >
            <div
              className={cx([styles.icon], {
                [styles.redStroke]: iconType === "RED_DELETE" || "ERROR",
              })}
            >
              <Icons type={iconType} />
            </div>
          </div>
          <div className={styles.main}>
            <div className={styles.header}>
              <p>{title}</p>
            </div>
            <div className={styles.btn}>
              <Button
                type={BUTTON_TYPES.SECONDARY}
                className={styles.button}
                onClick={onCancleClick}
              >
                Cancel
              </Button>
              <Button
                isLoading={isLoading}
                className={styles.button}
                onClick={handleSuccess}
              >
                {btnPlaceholder}
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

PopUp.propTypes = {
  isOpen: propTypes.bool,
  title: propTypes.string.isRequired,
  iconType: propTypes.string.isRequired,
  setIsOpen: propTypes.func.isRequired,
  btnPlaceholder: propTypes.string.isRequired,
  onSuccess: propTypes.func,
  id: propTypes.oneOfType([propTypes.string, propTypes.number]),
  onCancle: propTypes.func,
};

PopUp.defaultProps = {
  isOpen: false,
  onSuccess: noop,
  id: null,
  onCancle: noop,
};

export default PopUp;
