import _isEmpty from "lodash/isEmpty";
import _every from "lodash/every";
import { TOKENS } from "../constants/general.constants";
import { map } from "lodash";
import { MEDIA_URL } from "../axios";

export const validateEmptyField = (value, id) => {
  if (_isEmpty(value)) return `${id} can not be empty`;
  return "";
};

export const isEmptyError = (error) =>
  _every(error, (value) => _isEmpty(value));

export const getRandomColor = () => {
  const randomIndex = Math.floor(Math.random() * 20);
  const colors = [
    "#BC589A",
    "#2522B9",
    "#137149",
    "#917D4B",
    "#58AABC",
    "#BC5858",
    "#2270B9",
    "#711913",
    "#F35B04",
    "#7678EC",
    "#BC589A",
    "#2522B9",
    "#137149",
    "#917D4B",
    "#58AABC",
    "#BC5858",
    "#2270B9",
    "#711913",
    "#F35B04",
    "#7678EC",
  ];
  return colors[randomIndex];
};

export const LOCAL_STORAGE = {
  TOKEN: "token",
};

export const getParsedTokenData = (string) => {
  try {
    return JSON.parse(string);
  } catch (e) {
    return "";
  }
};

export const getToken = () => {
  const token = localStorage.getItem(TOKENS.ACCESS_TOKEN);
  return token;
};

export const getRefreshToken = () => {
  const token = localStorage.getItem(TOKENS.REFRESH_TOKEN);
  return token;
};

export const handleError = (error) => {
  const { response } = error;
  const { data } = response;
  const { message } = data;
  return message;
};

export const getFormattedPlaylists = (playlists) => {
  return playlists.map((playlist) => {
    return {
      ...playlist,
      color: getRandomColor(),
      songs: map(playlist.songs, (song) => {
        return {
          ...song,
          color: getRandomColor(),
        };
      }),
    };
  });
};

export const donwloadImageByName = (image) => {
  if (!image) return;

  return new Promise((resolve, reject) => {
    const img = new Image();
    img.src = `${MEDIA_URL}/songs_images/${image}`;
    img.crossOrigin = "anonymous";
    img.onload = () => {
      resolve(true);
    };
    img.onerror = () => {
      reject(false);
    };
  });
};
