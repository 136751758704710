import { debounce, filter, includes, map } from "lodash";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import PropTypes from "prop-types";

import { SearchInput } from "../../components/searchBar";
import SongListItem from "../../components/searchBar/songListItem";

import styles from "./editPlaylistPage.module.scss";
import songsStore from "../../zustand/songs.store";
import Button from "../../components/button/Button";
import { fetchSongs } from "../../actions/songs.actions";

const PlayListSearchSection = (props) => {
  const { addSong, alreadyAddedSongIds, isViewMode, setIsViewMode } = props;
  const { songs: initialSongs } = songsStore((state) => state);

  const [searchKey, setSearchKey] = useState("");
  const [isActive, setIsActive] = useState(false);

  // const songs = useMemo(
  //   () =>
  //     filter(initialSongs, (song) =>
  //       song?.title?.toLowerCase().includes(searchKey.toLowerCase())
  //     ),
  //   [initialSongs, searchKey]
  // );

  const [songs, setSongs] = useState(initialSongs);

  const handleSearchKeyChange = (e) => {
    const { value } = e.target;
    functionWithDebounce(value);
  };

  const functionToRun = async (val) => {
    const songs = await fetchSongs(val);
    setSongs(songs);
  };

  const functionWithDebounce = useCallback(
    debounce((val) => functionToRun(val), 400),
    []
  );

  useEffect(() => {
    setSongs(initialSongs);
  }, [initialSongs]);

  return (
    <div className={styles.right}>
      <div className={styles.songsContainerClassName}>
        <SearchInput
          searchKey={searchKey}
          onChange={handleSearchKeyChange}
          onFocus={() => setIsActive(true)}
          onBlur={() => setIsActive(false)}
          isActive={isActive}
        />
      </div>
      {isViewMode ? (
        <div
          className={styles.songsListContainer}
          style={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <Button
            onClick={() => {
              setIsViewMode(false);
            }}
          >
            Add More Songs
          </Button>
        </div>
      ) : (
        <div className={styles.songsListContainer}>
          {map(songs, (song, index) => {
            if (includes(alreadyAddedSongIds, song.id)) return null;
            return (
              <SongListItem
                key={song.id + "searcha bar song"}
                song={song}
                contentContianerClassName={styles.contentContianerClassName}
                songNameClassName={styles.songName}
                songArtistClassName={styles.songName}
                hasBtn
                onBtnClick={addSong}
                containerClassName={styles.songListItemContainer}
              />
            );
          })}
        </div>
      )}
    </div>
  );
};

PlayListSearchSection.propTypes = {
  addSong: PropTypes.func,
  alreadyAddedSongIds: PropTypes.array,
};

PlayListSearchSection.defaultProps = {
  addSong: () => {},
  alreadyAddedSongIds: [],
};

export default PlayListSearchSection;
