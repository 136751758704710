import * as React from "react";
const AddToWatchLater = (props) => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M11 21C9.75 21 8.579 20.7667 7.487 20.3C6.395 19.8333 5.441 19.1917 4.625 18.375C3.80833 17.5583 3.16667 16.604 2.7 15.512C2.23333 14.42 2 13.2493 2 12C2 10.75 2.23333 9.579 2.7 8.487C3.16667 7.395 3.80833 6.441 4.625 5.625C5.44167 4.80833 6.396 4.16667 7.488 3.7C8.58 3.23333 9.75067 3 11 3C11.35 3 11.6873 3.021 12.012 3.063C12.3367 3.105 12.666 3.16733 13 3.25V5.3C12.6667 5.2 12.3373 5.125 12.012 5.075C11.6867 5.025 11.3493 5 11 5C9.03333 5 7.375 5.675 6.025 7.025C4.675 8.375 4 10.0333 4 12C4 13.9667 4.675 15.625 6.025 16.975C7.375 18.325 9.03333 19 11 19C12.9667 19 14.625 18.325 15.975 16.975C17.325 15.625 18 13.9667 18 12C18 11.8167 17.9917 11.65 17.975 11.5C17.9583 11.35 17.9333 11.1833 17.9 11H19.95C19.9833 11.1833 20 11.35 20 11.5V12C20 13.25 19.7667 14.421 19.3 15.513C18.8333 16.605 18.1917 17.559 17.375 18.375C16.5583 19.1917 15.604 19.8333 14.512 20.3C13.42 20.7667 12.2493 21 11 21ZM13.8 16.2L10 12.4V7H12V11.6L15.2 14.8L13.8 16.2ZM18 9V6H15V4H18V1H20V4H23V6H20V9H18Z"
      fill="#515151"
    />
  </svg>
);
export default AddToWatchLater;
