import React, { memo } from 'react';
import cx from 'classnames';
import propTypes from 'prop-types';

import _get from 'lodash/get';

import Icon from '../../icons';
import { Link, useLocation } from 'react-router-dom';
import { ROUTES } from '../../../constants/routes.constants';

import styles from './sideDrawerListItem.module.scss';

const SideDrawerListItem = ({ icon, title, route }) => {
  const location = useLocation();

  const pathname = _get(location, 'pathname', ROUTES.HOME);

  return (
    <Link to={route}>
      <div
        className={cx(styles.dashboardMenuList, {
          [styles.active]: route === pathname,
        })}>
        <Icon type={icon} />
        <p>{title}</p>
      </div>
    </Link>
  );
};

SideDrawerListItem.propTypes = {
  icon: propTypes.string,
  title: propTypes.string,
  hasStroke: propTypes.bool,
  route: propTypes.string,
};

SideDrawerListItem.defaultProps = {
  icon: '',
  title: '',
  hasStroke: true,
  route: ROUTES.HOME,
};

export default memo(SideDrawerListItem);
