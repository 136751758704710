import * as React from 'react';
const LikeSongList = (props) => (
  <svg
    width={38}
    height={38}
    viewBox="0 0 38 38"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path
      d="M18.4464 13.2795C18.5885 13.435 18.7894 13.5235 19 13.5235C19.2105 13.5235 19.4114 13.435 19.5535 13.2795C21.5239 11.1243 24.6883 11.1243 26.6586 13.2795C28.6645 15.4736 28.6645 19.0563 26.6586 21.2503L19.9225 28.6185C19.4269 29.1606 18.573 29.1606 18.0774 28.6185L11.3413 21.2503C9.33546 19.0563 9.33546 15.4736 11.3413 13.2795C13.3117 11.1243 16.476 11.1243 18.4464 13.2795Z"
      stroke="white"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default LikeSongList;
