import * as React from 'react';
const Upload = (props) => (
  <svg
    width={26}
    height={26}
    viewBox="0 0 26 26"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M19.5 16.2502V19.5002H6.49998V16.2502H4.33331V19.5002C4.33331 20.6918 5.30831 21.6668 6.49998 21.6668H19.5C20.6916 21.6668 21.6666 20.6918 21.6666 19.5002V16.2502H19.5ZM7.58331 9.75016L9.11081 11.2777L11.9166 8.48266V17.3335H14.0833V8.48266L16.8891 11.2777L18.4166 9.75016L13 4.3335L7.58331 9.75016Z"
      fill="#D1D1D1"
    />
  </svg>
);
export default Upload;
