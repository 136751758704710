const converToTwoDigit = (num) => {
  if (!num) return "00";
  return num < 10 ? `0${num}` : num;
};

export const getFormattedTime = (values) => {
  const hours = values?.hours || 0;
  const mins = values?.mins || 0;
  const type = values?.type || 1;
  let hoursInt = parseInt(hours);
  const minsInt = parseInt(mins);
  if (type === 2) {
    hoursInt = hoursInt + 12;
  }
  return `${converToTwoDigit(hoursInt)}:${converToTwoDigit(minsInt)}`;
};
