import * as React from 'react';
const Music = (props) => (
  <svg
    width={26}
    height={26}
    viewBox="0 0 26 26"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M22 4.66868V16.334C22 16.9273 21.8099 17.5072 21.4539 18.0005C21.0979 18.4938 20.5919 18.8783 19.9999 19.1053C19.4078 19.3323 18.7564 19.3917 18.1279 19.276C17.4994 19.1603 16.9221 18.8746 16.469 18.4551C16.0158 18.0356 15.7073 17.5011 15.5822 16.9192C15.4572 16.3373 15.5214 15.7342 15.7666 15.1861C16.0118 14.638 16.4271 14.1695 16.9599 13.8399C17.4927 13.5103 18.1192 13.3343 18.76 13.3343C19.4016 13.3316 20.0292 13.5088 20.56 13.8426V5.51858L10.48 7.85164V19.0003C10.48 19.5936 10.29 20.1736 9.93395 20.6669C9.57794 21.1602 9.07192 21.5446 8.47989 21.7717C7.88786 21.9987 7.2364 22.0581 6.6079 21.9424C5.97941 21.8266 5.40209 21.5409 4.94897 21.1214C4.49585 20.7019 4.18727 20.1674 4.06226 19.5856C3.93724 19.0037 4.0014 18.4005 4.24663 17.8524C4.49186 17.3043 4.90714 16.8358 5.43995 16.5062C5.97277 16.1766 6.59918 16.0007 7.24 16.0007C7.88167 15.9979 8.5092 16.1751 9.03999 16.509V7.33504C9.03941 7.18537 9.09324 7.03988 9.19283 6.92199C9.29242 6.80409 9.43196 6.72066 9.58899 6.68511L21.109 4.01876C21.2145 3.99492 21.3246 3.99379 21.4306 4.01545C21.5367 4.03712 21.636 4.08101 21.721 4.14374C21.8086 4.20496 21.8794 4.28434 21.9279 4.3755C21.9763 4.46667 22.001 4.56708 22 4.66868V4.66868Z"
      fill="white"
    />
  </svg>
);
export default Music;
