import axios from "../axios";

const loginUser = (payload) => axios.post("/login", payload);

const fetchCustomerData = async () => axios.get("v1/customer/data", {});

const getNewAccessToken = (payload) => axios.post("/refreshtoken", payload);

export const userService = {
  loginUser,
  fetchCustomerData,
  getNewAccessToken,
};
