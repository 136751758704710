import React, { useCallback } from 'react';
import cx from 'classnames';
import propTypes from 'prop-types';

import _noop from 'lodash/noop';

import styles from './checkbox.module.scss';
import Icons from '../icons';
import { ICONS } from '../../constants/icons.constants';

const Checkbox = ({ isChecked, setIsChecked, disabled, label }) => {
  const toggleIsChecked = useCallback(() => {
    setIsChecked(!isChecked);
  }, [setIsChecked, isChecked]);

  return (
    <div className={styles.container}>
      <div
        className={cx({
          [styles.disabled]: disabled,
          [styles.checkbox]: !disabled,
          [styles.checked]: isChecked && !disabled,
          [styles.disableChecked]: !isChecked && disabled,
        })}
        onClick={toggleIsChecked}>
        {isChecked && <Icons type={ICONS.CHECKBOX} height={15} width={15} />}
      </div>
      <div className={styles.label}>{label}</div>
    </div>
  );
};

Checkbox.propTypes = {
  isChecked: propTypes.bool,
  setIsChecked: propTypes.func,
  disabled: propTypes.bool,
  label: propTypes.string,
};

Checkbox.defaultProps = {
  isChecked: false,
  setIsChecked: _noop,
  disabled: false,
  label: '',
};

export default Checkbox;
