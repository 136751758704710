import React from "react";

import Button from "../button";
import propTypes from "prop-types";
import cx from "classnames";

import { noop } from "lodash";

import styles from "./alertPopup.module.scss";
import { BUTTON_TYPES } from "../button/Button";

const AlertPopup = ({ isOpen }) => {
  const onBackgroundClick = (e) => {
    if (e) e.stopPropagation();
  };

  return (
    <div id="alreadyRunning" className={cx([styles.popUpWrapper], { [styles.popUpOpen]: isOpen })}>
      <div className={styles.popUpBg} onClick={onBackgroundClick}></div>
      <div
        className={styles.popUpChildren}
        onClick={(e) => e.stopPropagation()}
      >
        <div className={styles.deleteEventPopUp}>
          <div className={styles.main}>
            <div className={styles.header}>
              <p>{`4Play is already running in another tab.`}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

AlertPopup.propTypes = {
  isOpen: propTypes.bool,
  title: propTypes.string.isRequired,
  iconType: propTypes.string.isRequired,
  setIsOpen: propTypes.func.isRequired,
  btnPlaceholder: propTypes.string.isRequired,
  onSuccess: propTypes.func,
  id: propTypes.oneOfType([propTypes.string, propTypes.number]),
  onCancle: propTypes.func,
};

AlertPopup.defaultProps = {
  isOpen: false,
  onSuccess: noop,
  id: null,
  onCancle: noop,
};

export default AlertPopup;
