import {
  add,
  filter,
  findIndex,
  get,
  includes,
  isEmpty,
  map,
  size,
} from "lodash";
import React, { useEffect, useState, useRef, useCallback } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import propTypes from "prop-types";

import Button from "../../components/button/Button";
import Icons from "../../components/icons";
import PlaylistSong from "../../components/playlistSong/PlaylistSong";
import PlayListSearchSection from "./SearchSection";

import { ICONS } from "../../constants/icons.constants";
import { ROUTES } from "../../constants/routes.constants";
import { getSongs } from "./editPlayListPage.helpers";
import playlistStore from "../../zustand/playlist.store";
import { showToaster } from "../../helpers/toaster.helpers";
import { getPlaylistUpdatePayload } from "../../helpers/playlist.helpers";
import {
  addImage,
  addPlaylist,
  updatePlaylist,
} from "../../actions/playlist.actions";
import {
  donwloadImageByName,
  getRandomColor,
} from "../../helpers/generel.helpers";

import styles from "./editPlaylistPage.module.scss";
import musicPlayerStore from "../../zustand/musicPlayer.store";
import likedSongsStore from "../../zustand/likedSong.store";
import {
  addSongToFavorites,
  removeSongFromFavorites,
} from "../../actions/songs.actions";
import ThumbNail from "../../components/thumbNail/ThumbNail";
import { shuffleArray } from "../../helpers/music.helpers";
import { MEDIA_URL } from "../../axios";
import imageStore from "../../zustand/image.store";

const EditPlaylistPage = () => {
  const [isNameEdit, setIsNameEdit] = useState(false);
  const [values, setValues] = useState({
    visibleImage: "", // this we'll show in the UI
    imageForBE: null, // this we'll send to the server
  });
  const [isLoading, setIsLoading] = useState(false);
  const [isViewMode, setIsViewMode] = useState(true);

  const inputFile = useRef(null);
  const isSomethingChanged = useRef(false);

  const navigate = useNavigate();
  const currentPath = useLocation();
  const params = useParams();

  const {
    playlists,
    addNewPlaylist,
    updatePlaylist: updatePlaylistState,
    addLikedSongToPlaylist,
    removeUnLikedSongFromPlaylist,
  } = playlistStore();
  const { setSongs, setPlayList, isShuffle, setBeforeShuffleSongs } =
    musicPlayerStore();
  const {
    songs: likedSongs,
    addSong: addLikedSong,
    deleteSong: deleteLikedSong,
  } = likedSongsStore();
  const { addImage } = imageStore();

  const currentPlayList = getSongs(params, playlists);
  const { songs: initialSongs, name, isNewPlayList, image } = currentPlayList;

  useEffect(() => {
    setValues({
      songs: initialSongs,
      name: name,
    });
  }, [params?.id, playlists]);

  useEffect(() => {
    if (!isNewPlayList) {
      setValues((prev) => ({
        ...prev,
        songs: initialSongs,
      }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // useEffect(() => {
  //   const handleBeforeUnload = (e) => {
  //     e.preventDefault();
  //     e.returnValue = "";
  //     window.alert("Are you sure you want to leave this page?");
  //   };

  //   const handleNavigate = (e) => {
  //     e.preventDefault();
  //     window.alert("Navigation is disabled on this page.");
  //   };

  //   window.addEventListener("beforeunload", handleBeforeUnload);
  //   return () => {
  //     window.removeEventListener("beforeunload", handleBeforeUnload);
  //   };
  // }, []);

  const handleEditClick = () => {
    setIsViewMode(false);
    setIsNameEdit(!isNameEdit);
  };
  const handleLikeClick = (song, favorite) => async (e) => {
    e.stopPropagation();

    const { index = "1.0", id } = song;
    let isSuccessful = false;
    const isAlreadyLiked = favorite;

    if (isAlreadyLiked) {
      deleteLikedSong(id);
      removeUnLikedSongFromPlaylist(song);
    } else {
      addLikedSong(id);
      addLikedSongToPlaylist(song);
    }

    if (isAlreadyLiked) {
      isSuccessful = await removeSongFromFavorites({ song_id: id });
    } else {
      isSuccessful = await addSongToFavorites({ song_id: id, index });
    }

    if (!isSuccessful) {
      if (isAlreadyLiked) {
        addLikedSong(id);
      } else {
        deleteLikedSong(id);
      }
      return;
    }
  };

  const handleInputChange = (id) => (e) => {
    isSomethingChanged.current = true;
    setValues({
      ...values,
      [id]: e.target.value,
    });
  };

  const handleBack = () => {
    navigate(ROUTES.MY_PLAYLIST);
  };

  const addSong = (newSong) => {
    isSomethingChanged.current = true;
    setValues({
      ...values,
      songs: [...(values?.songs || []), newSong],
    });
  };

  const removeSong = (id) => {
    const updatedSongs = filter(values?.songs, (song) => song.id !== id);
    setValues({
      ...values,
      songs: updatedSongs,
    });
  };

  const uploadImage = async (id) => {
    if (!isEmpty(values.visibleImage)) {
      const formData = new FormData();
      formData.append("image", values.imageForBE);
      await addImage(id, formData);
    }
  };

  const handleAddNewPlaylist = async () => {
    setIsLoading(true);
    const payload = getPlaylistUpdatePayload(values);
    const playlistId = await addPlaylist(payload);
    await uploadImage(playlistId);
    setIsLoading(false);
    addNewPlaylist({
      name: values.name,
      id: playlistId,
      color: getRandomColor(),
      songs: values.songs,
      owner: true,
      is_favorite: false,
      schedule: [],
      visibelePhoto: values.visibleImage,
    });
    navigate(ROUTES.MY_PLAYLIST);
  };

  const handelEditPlaylist = async () => {
    setIsLoading(true);
    const payload = getPlaylistUpdatePayload(values);
    // console.log(values);
    await updatePlaylist({ ...payload, id: params.id });
    await uploadImage(params.id);
    setIsLoading(false);
    updatePlaylistState(
      { ...values, visibelePhoto: values.visibleImage },
      params.id
    );
    navigate(ROUTES.MY_PLAYLIST);
  };

  const handleEditSuccess = () => {
    if (isEmpty(values.songs)) {
      showToaster("error", "Please add songs to playlist");

      return;
    }
    if (currentPath.pathname === ROUTES.NEW_PLAYLIST) {
      handleAddNewPlaylist();
    }
    if (!isNewPlayList) {
      handelEditPlaylist();
    }
  };

  const handleUploadImg = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        if (reader.readyState === 2) {
          setValues((prev) => ({
            ...prev,
            visibleImage: reader.result.toString(),
            imageForBE: e.target.files[0],
          }));
        }
      };
      reader.readAsDataURL(e.target.files[0]);
    }
  };

  const handlePlayClick = async (songId) => {
    // const songsAfterIgnore = filter(values.songs, (song) => !song.ignore);
    let songIndex = findIndex(values.songs, (song) => song.id === songId);

    if (songIndex === -1) {
      songIndex = 0;
    }

    const shuffledSongs = isShuffle
      ? shuffleArray([...values.songs], songIndex)
      : values.songs;

    if (isShuffle) {
      setBeforeShuffleSongs([...values.songs]);
    }
    setSongs(shuffledSongs, songIndex);
    setPlayList({ ...params, songIndex });
    navigate(ROUTES.HOME);
  };

  const donwloadPlayListImage = useCallback(() => {
    if (!image) return;
    const img = new Image();
    img.src = `${MEDIA_URL}/playlist_images/${image}`;
    img.crossOrigin = "anonymous";
    img.onload = () => {
      addImage(image);
    };
    img.onerror = () => {
      //
    };
  }, [addImage, image]);

  const donwloadImage = useCallback(async () => {
    const songs = values?.songs || [];
    for (let index = 0; index < size(songs); index++) {
      if (index >= size(songs)) return;

      const imageName = get(songs?.[index], "imageFileName");
      try {
        const result = await donwloadImageByName(imageName);
        if (result) {
          addImage(imageName);
        }
      } catch (error) {
        //
      }
    }
  }, [values.songs]);

  useEffect(() => {
    donwloadImage();
    donwloadPlayListImage();
  }, [donwloadImage, donwloadPlayListImage]);

  return (
    <div className={styles.pageContent}>
      <div className={styles.left}>
        <div className={styles.header}>
          <div>
            <div onClick={handleBack} className={styles.backIcon}>
              <Icons type={ICONS.BACK_ARROW} />
            </div>
            {!isNewPlayList ? <p>{name}</p> : <p>Create Playlist</p>}
          </div>
        </div>

        <div className={styles.head}>
          <div className={styles.head2}>
            <div
              className={styles.headImg}
              // onClick={() => inputFile.current.click()}
            >
              <ThumbNail
                name={values.name}
                imageFileName={image}
                imgSrc={`${MEDIA_URL}/playlist_images/${image}`}
              />
              {/* <input
                // allow only images
                type="file"
                accept="image/*"
                ref={inputFile}
                className={styles.upload}
                onChange={handleUploadImg}
              /> */}
            </div>
            <div className={styles.mainBox}>
              <div className={styles.box1}>
                <div className={styles.box3}>
                  {!isNameEdit ? (
                    <p className={styles.name}>{values.name}</p>
                  ) : (
                    <input
                      className={styles.nameInput}
                      value={values.name}
                      autoFocus={true}
                      onChange={handleInputChange("name")}
                      onBlur={handleEditClick}
                    />
                  )}
                  <div className={styles.backIcon} onClick={handleEditClick}>
                    <Icons type={ICONS.EDIT} />
                  </div>
                </div>
                <div className={styles.editBtn}>
                  {isViewMode ? (
                    <Button
                      disabled={isEmpty(values.songs)}
                      isLoading={isLoading}
                      onClick={handlePlayClick}
                    >
                      Play
                    </Button>
                  ) : (
                    <Button isLoading={isLoading} onClick={handleEditSuccess}>
                      Save
                    </Button>
                  )}
                </div>
              </div>

              <div className={styles.box2}>
                <p>{`${size(values?.songs)} songs`}</p>
              </div>
            </div>
          </div>
        </div>

        <div className={styles.songsBox}>
          {map(values?.songs, (item, i) => {
            const { title, artist, id, color, ignore, index, imageFileName } =
              item;
            return (
              <div
                key={id + "palylist" + i}
                onClick={() => handlePlayClick(id)}
              >
                <PlaylistSong
                  key={id + "palylist" + i}
                  songName={title}
                  artistName={artist}
                  id={id}
                  color={color}
                  removeSong={removeSong}
                  removeView={!isViewMode}
                  playListView={isViewMode}
                  index={index}
                  isViewMode={isViewMode}
                  liked={likedSongs?.[id]}
                  onLikeClick={handleLikeClick(item, likedSongs?.[id])}
                  imageFileName={imageFileName}
                />
              </div>
            );
          })}
        </div>
      </div>
      {currentPath.pathname === ROUTES.NEW_PLAYLIST ? (
        <PlayListSearchSection
          addSong={addSong}
          alreadyAddedSongIds={map(values?.songs, "id")}
          isViewMode={isViewMode}
          setIsViewMode={setIsViewMode}
        />
      ) : null}
    </div>
  );
};

EditPlaylistPage.propTypes = {
  isEdit: propTypes.bool,
};

EditPlaylistPage.defaultProps = {
  isEdit: false,
};

export default EditPlaylistPage;
