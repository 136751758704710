import * as React from 'react';
const Play = (props) => (
  <svg
    width={38}
    height={38}
    viewBox="0 0 38 38"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M35 19C35 27.8366 27.8366 35 19 35C10.1634 35 3 27.8366 3 19C3 10.1634 10.1634 3 19 3C27.8366 3 35 10.1634 35 19Z"
      fill="#6B3EB4"
    />
    <path
      d="M15 15C15 14.4477 15.4477 14 16 14H17C17.5523 14 18 14.4477 18 15V23C18 23.5523 17.5523 24 17 24H16C15.4477 24 15 23.5523 15 23V15Z"
      fill="black"
    />
    <path
      d="M21 15C21 14.4477 21.4477 14 22 14H23C23.5523 14 24 14.4477 24 15V23C24 23.5523 23.5523 24 23 24H22C21.4477 24 21 23.5523 21 23V15Z"
      fill="black"
    />
  </svg>
);
export default Play;
