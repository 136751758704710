import { isEmpty } from "lodash";

export const timeFormatConverter = (time) => {
  if (isEmpty(time)) {
    return;
  }
  time = time.toString().match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [
    time,
  ];

  if (time?.length > 1) {
    time = time.slice(1);
    time[5] = +time[0] < 12 ? " AM" : " PM";
    time[0] = +time[0] % 12 || 12;
  }
  return time.join("");
};

const convertToTwoDigit = (number) => {
  if (parseInt(number) < 10) return `0${number}`;
  return number;
};

export const getTwentyFourHourTime = (amPmString) => {
  var d = new Date("1/1/2013 " + amPmString);
  return d.getHours() + ":" + d.getMinutes();
};

export const createSchedulePaylod = (values) => {
  const { name, selectedPlayingHours, slectedPlayingDays, selectedPlaylists } =
    values || {};

  const payload = {
    name,
    days: slectedPlayingDays,
    time: getTwentyFourHourTime(selectedPlayingHours),
  };
  return {
    payload,
    ids: selectedPlaylists,
  };
};

getTwentyFourHourTime("8:45 PM"); // "20:45"
