import { toast } from "react-toastify";

type toasterTypes =
  | "error"
  | "success"
  | "info"
  | "warning"
  | "dark"
  | "default";

export const showToaster = (type: toasterTypes, message: string) =>
  toast[type](message);
