import * as React from 'react';
const RedDelete = (props) => (
  <svg
    width={22}
    height={24}
    viewBox="0 0 22 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M8.5 4H13.5C13.5 3.33696 13.2366 2.70107 12.7678 2.23223C12.2989 1.76339 11.663 1.5 11 1.5C10.337 1.5 9.70107 1.76339 9.23223 2.23223C8.76339 2.70107 8.5 3.33696 8.5 4ZM7 4C7 2.93913 7.42143 1.92172 8.17157 1.17157C8.92172 0.421427 9.93913 0 11 0C12.0609 0 13.0783 0.421427 13.8284 1.17157C14.5786 1.92172 15 2.93913 15 4H21.25C21.4489 4 21.6397 4.07902 21.7803 4.21967C21.921 4.36032 22 4.55109 22 4.75C22 4.94891 21.921 5.13968 21.7803 5.28033C21.6397 5.42098 21.4489 5.5 21.25 5.5H19.94L18.723 20.103C18.6345 21.1653 18.1499 22.1556 17.3655 22.8774C16.5811 23.5992 15.554 23.9999 14.488 24H7.512C6.44599 23.9999 5.41894 23.5992 4.6345 22.8774C3.85007 22.1556 3.36554 21.1653 3.277 20.103L2.06 5.5H0.75C0.551088 5.5 0.360322 5.42098 0.21967 5.28033C0.0790175 5.13968 0 4.94891 0 4.75C0 4.55109 0.0790175 4.36032 0.21967 4.21967C0.360322 4.07902 0.551088 4 0.75 4H7ZM4.772 19.978C4.82919 20.6654 5.14262 21.3062 5.65015 21.7734C6.15767 22.2405 6.82222 22.4999 7.512 22.5H14.488C15.1778 22.4999 15.8423 22.2405 16.3499 21.7734C16.8574 21.3062 17.1708 20.6654 17.228 19.978L18.436 5.5H3.565L4.772 19.978ZM8.75 9C8.94891 9 9.13968 9.07902 9.28033 9.21967C9.42098 9.36032 9.5 9.55109 9.5 9.75V18.25C9.5 18.4489 9.42098 18.6397 9.28033 18.7803C9.13968 18.921 8.94891 19 8.75 19C8.55109 19 8.36032 18.921 8.21967 18.7803C8.07902 18.6397 8 18.4489 8 18.25V9.75C8 9.55109 8.07902 9.36032 8.21967 9.21967C8.36032 9.07902 8.55109 9 8.75 9ZM14 9.75C14 9.55109 13.921 9.36032 13.7803 9.21967C13.6397 9.07902 13.4489 9 13.25 9C13.0511 9 12.8603 9.07902 12.7197 9.21967C12.579 9.36032 12.5 9.55109 12.5 9.75V18.25C12.5 18.4489 12.579 18.6397 12.7197 18.7803C12.8603 18.921 13.0511 19 13.25 19C13.4489 19 13.6397 18.921 13.7803 18.7803C13.921 18.6397 14 18.4489 14 18.25V9.75Z"
      fill="#DD4E47"
    />
  </svg>
);
export default RedDelete;
