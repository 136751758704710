import React, { memo } from "react";
import cx from "classnames";
import { map } from "lodash";

import SideDrawerListItem from "./sideDrawerListItem/SideDrawerListItem";
import Icons from "../icons";
import { ICONS } from "../../constants/icons.constants";
import { ROUTES } from "../../constants/routes.constants";

import styles from "./sideDrawer.module.scss";
import LoadingSkeleton from "../loadingSkeleton/LoadingSkeleton";
import { useLocation, useNavigate } from "react-router-dom";
import playlistStore from "../../zustand/playlist.store";
//
import logo from "../../logo.png";

const SideDrawer = () => {
  const { playlists, isLoading } = playlistStore((state) => state);

  const navigate = useNavigate();
  const currentLocation = useLocation();

  const onPlayListClick = (id) => () => {
    // dispatch(setCurretPlayList(id));
    navigate(`/my-playlist/view/${id}`);
  };

  return (
    <div className={styles.dashboard}>
      <div className={styles.header}>
        {/* <Icons type={ICONS.MAIN_LOGO} /> */}
        <img src={logo} alt="logo" className={styles.logo} />
      </div>
      <SideDrawerListItem icon={ICONS.HOME} title="Home" route={ROUTES.HOME} />
      <SideDrawerListItem
        icon={ICONS.MY_SCHEDULE}
        title="My Schedule"
        route={ROUTES.MY_SCHEDULE}
      />
      <SideDrawerListItem
        icon={ICONS.LIKE_NAVBAR}
        title="Liked"
        route={ROUTES.LIKED}
      />
      <SideDrawerListItem
        icon={ICONS.MY_PLAYLIST}
        title="My Playlist"
        route={ROUTES.MY_PLAYLIST}
      />

      <div className={styles.playlist}>
        {!isLoading ? (
          map(playlists, ({ name, id }) => (
            <div className={styles.playlistTextContainer}>
              <div
                onClick={onPlayListClick(id)}
                className={cx(styles.playlistText, {
                  [styles.active]:
                    `/my-playlist/view/${id}` === currentLocation.pathname,
                })}
                key={id + "side drawer paylist"}
              >
                {name}
              </div>
              {/* tooltip */}
              <div className={styles.customTooltip}>{name}</div>
            </div>
          ))
        ) : (
          <>
            <LoadingSkeleton isPlaylist />
            <LoadingSkeleton isPlaylist />
            <LoadingSkeleton isPlaylist />
            <LoadingSkeleton isPlaylist />
          </>
        )}
      </div>
      <div className={styles.profileIcon}>
        <SideDrawerListItem
          icon={ICONS.PROFILE}
          title="Profile"
          route={ROUTES.PROFILE}
        />
      </div>
    </div>
  );
};

// SideDrawer.propTypes = {
//   username: propTypes.string,
//   children: propTypes.element,
// };

// SideDrawer.defaultProps = {
//   username: '',
//   children: <></>,
// };

export default memo(SideDrawer);
