import { get, map } from "lodash";
import { getRandomColor } from "../helpers/generel.helpers";
import { songsServices } from "../services/song.service";
import { socketServices } from "../services/socket.service";

const addColorToSong = (songs) => {
  return map(songs, (song, index) => {
    const color = getRandomColor();
    return { ...song, color };
  });
};

export const fetchSongs = async (searchKey = "") => {
  try {
    const { data } = await songsServices.fetchSongs(searchKey);
    return addColorToSong(data);
  } catch (error) {
    return {};
  }
};

export const addSongToFavorites = async (payload) => {
  try {
    let socketPayload = {
      eventname: "song_liked",
      data: {
        song_id: payload.song_id,
        song_name: payload.song_name,
        artist_name: payload.artist_name,
        status: true,
      },
    };
    socketServices.sendLikedSongStatus(socketPayload);
    await songsServices.addSongToFavorites(payload);
    return true;
  } catch (error) {
    return false;
  }
};

export const removeSongFromFavorites = async (payload) => {
  try {
    let socketPayload = {
      eventname: "song_liked",
      data: {
        song_id: payload.song_id,
        song_name: payload.song_name,
        artist_name: payload.artist_name,
        status: false,
      },
    };
    socketServices.sendLikedSongStatus(socketPayload);
    await songsServices.removeSongFromFavorites(payload);
    return true;
  } catch (error) {
    return false;
  }
};

export const fetchLikedSongs = async () => {
  try {
    const { data } = await songsServices.fetchLikedSongs();
    const songs = get(data, "songs", []);
    return addColorToSong(songs);
  } catch (error) {
    return {};
  }
};
