import { filter, head, isEmpty, map } from "lodash";

export const getSongs = (params, playlists) => {
  const { id } = params;
  const currentPlayList = head(
    filter(playlists, (item) => item?.id?.toString() === id?.toString())
  );

  return isEmpty(currentPlayList)
    ? {
        songs: [],
        name: "New Playlist",
        isNewPlayList: true,
      }
    : currentPlayList;
};

export const getFormatedPlaylist = (values) => {
  // const songs = filter(value.songs, )
  const songs = values.songs;

  const formatedSongs = map(songs, (song, i) => {
    return {
      id: song?.id,
      index: i,
    };
  });
  return {
    name: values.name,
    songs: formatedSongs,
    songsForRedux: songs,
  };
};
