import React from "react";
import cx from "classnames";
import PropTypes from "prop-types";

import Icons from "../../icons";
import { ICONS } from "../../../constants/icons.constants";

import styles from "./searchInput.module.scss";

const SearchInput = (props) => {
  const { onBlur, onFocus, isActive, searchKey, onChange } = props;

  return (
    <div className={styles.inputBoxContainer}>
      <div className={styles.mainBox}>
        <div
          className={cx(styles.icon, {
            [styles.active]: isActive,
          })}
        >
          <Icons type={ICONS.SEARCH} />
        </div>
        <div className={cx(styles.inputBox)}>
          <input
            placeholder="Find Your Music"
            onChange={onChange}
            // value={searchKey}
            onBlur={onBlur}
            onFocus={onFocus}
          />
        </div>
      </div>
    </div>
  );
};

SearchInput.propTypes = {
  onBlur: PropTypes.func,
  onFocus: PropTypes.func,
  isActive: PropTypes.bool,
  searchKey: PropTypes.string,
  onChange: PropTypes.func,
};

SearchInput.defaultProps = {
  onBlur: () => {},
  onFocus: () => {},
  isActive: false,
  searchKey: "",
  onChange: () => {},
};

export default SearchInput;
