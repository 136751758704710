import * as React from 'react';
const Pause = (props) => (
  <svg
    width={38}
    height={38}
    viewBox="0 0 38 38"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M19.167 3.1665C16.0025 3.1665 12.9091 4.10489 10.2779 5.86299C7.64669 7.62109 5.59593 10.12 4.38493 13.0436C3.17392 15.9672 2.85707 19.1843 3.47443 22.2879C4.0918 25.3916 5.61565 28.2426 7.85329 30.4802C10.0909 32.7179 12.9419 34.2417 16.0456 34.8591C19.1492 35.4764 22.3663 35.1596 25.2899 33.9486C28.2135 32.7376 30.7124 30.6868 32.4705 28.0556C34.2286 25.4244 35.167 22.331 35.167 19.1665C35.167 17.0654 34.7531 14.9848 33.9491 13.0436C33.145 11.1024 31.9664 9.33853 30.4807 7.8528C28.995 6.36706 27.2311 5.18851 25.2899 4.38443C23.3487 3.58036 21.2681 3.1665 19.167 3.1665ZM16.0456 24.4371V13.0534L24.814 19.1665L16.0456 24.4371Z"
      fill="#7441C7"
    />
  </svg>
);
export default Pause;
