import * as React from 'react';
const Logout = (props) => (
  <svg
    width={26}
    height={26}
    viewBox="0 0 26 26"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.6314 20.7039C11.6314 20.4485 11.5353 20.2035 11.3642 20.0229C11.1931 19.8424 10.9611 19.7409 10.7191 19.7409H6.15784V6.25942H10.7191C10.9611 6.25942 11.1931 6.15797 11.3642 5.97738C11.5353 5.79679 11.6314 5.55185 11.6314 5.29646C11.6314 5.04107 11.5353 4.79613 11.3642 4.61554C11.1931 4.43495 10.9611 4.3335 10.7191 4.3335H6.15784C5.67394 4.3335 5.20987 4.53641 4.8677 4.89759C4.52554 5.25877 4.33331 5.74863 4.33331 6.25942V19.7409C4.33331 20.2517 4.52554 20.7416 4.8677 21.1027C5.20987 21.4639 5.67394 21.6668 6.15784 21.6668H10.7191C10.9611 21.6668 11.1931 21.5654 11.3642 21.3848C11.5353 21.2042 11.6314 20.9593 11.6314 20.7039Z"
      fill="white"
    />
    <path
      d="M21.4059 13.6741C21.5728 13.495 21.6664 13.254 21.6668 13.0029V12.9971C21.6663 12.7427 21.5702 12.4988 21.3995 12.3192L17.7505 8.46732C17.6663 8.37535 17.5657 8.30199 17.4544 8.25152C17.3431 8.20105 17.2233 8.17449 17.1022 8.17338C16.9811 8.17227 16.861 8.19663 16.7488 8.24505C16.6367 8.29347 16.5349 8.36497 16.4492 8.45538C16.3636 8.5458 16.2958 8.65332 16.25 8.77166C16.2041 8.89001 16.181 9.01681 16.1821 9.14467C16.1831 9.27253 16.2083 9.39889 16.2561 9.51638C16.3039 9.63386 16.3734 9.74012 16.4605 9.82895L18.5523 12.037H9.8074C9.56546 12.037 9.33342 12.1385 9.16234 12.3191C8.99125 12.4997 8.89514 12.7446 8.89514 13C8.89514 13.2554 8.99125 13.5003 9.16234 13.6809C9.33342 13.8615 9.56546 13.963 9.8074 13.963H18.5523L16.4605 16.171C16.2944 16.3526 16.2024 16.5959 16.2045 16.8484C16.2066 17.1009 16.3025 17.3424 16.4716 17.5209C16.6408 17.6995 16.8696 17.8007 17.1088 17.8029C17.348 17.8051 17.5784 17.7081 17.7505 17.5327L21.3995 13.6808L21.4059 13.6741V13.6741Z"
      fill="white"
    />
  </svg>
);
export default Logout;
