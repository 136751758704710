import React from "react";
import LoadingSkeleton from "../../components/loadingSkeleton/LoadingSkeleton";

import styles from "./homepage.module.scss";

const Loader = () => (
  <div className={styles.page}>
    <div className={styles.first}>
      <div className={styles.topBox}>
        <LoadingSkeleton isHomepageTop />
      </div>
    </div>
    <div className={styles.second}>
      <LoadingSkeleton isHomepage />
      <LoadingSkeleton isHomepage />
      <LoadingSkeleton isHomepage />
      <LoadingSkeleton isHomepage />
      <LoadingSkeleton isHomepage />
      <LoadingSkeleton isHomepage />
      <LoadingSkeleton isHomepage />
      <LoadingSkeleton isHomepage />
      <LoadingSkeleton isHomepage />
      <LoadingSkeleton isHomepage />
      <LoadingSkeleton isHomepage />
    </div>
  </div>
);

export default Loader;
