import { create } from "zustand";
import { Song } from "../types/song.types";

interface Songs {
  isLoading: boolean;
  error: string;
  songs: Song[];
  setSongs: (songs: Song[]) => void;
  setIsLoading: (isLoading: boolean) => void;
  toogleFavorite: (songid: string) => void;
}

const songsStore = create<Songs>((set) => ({
  isLoading: false,
  error: "",
  songs: [],

  setSongs: (songs: Song[]) => {
    set({ songs });
  },
  setIsLoading: (isLoading: boolean) => {
    set({ isLoading });
  },
  toogleFavorite: (songid: string) => {
    set((state) => {
      const songs = state.songs.map((song) => {
        if (song.id === songid) {
          song.favorite = !song.favorite;
        }
        return song;
      });
      return { songs };
    });
  },
}));

export default songsStore;
