import React, { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { isEmpty, map, size } from "lodash";

import Button from "../../components/button/Button";
import SongList from "../../components/songList/SongList";
import NillDisplaySchedule from "../../components/nillDisplaySchedule/NillDisplaySchedule";
import Header from "../../components/Header";

import { ICONS } from "../../constants/icons.constants";
import playlistStore from "../../zustand/playlist.store";

import styles from "./myPlayListPage.module.scss";
import Loader from "./Loader";
import { MEDIA_URL } from "../../axios";
import imageStore from "../../zustand/image.store";

const MyPlayListPage = () => {
  const [isVisible, setIsVisible] = useState(false);
  const [currentPlaylistId, setCurrentPlaylistId] = useState("");

  const { playlists, isLoading } = playlistStore((state) => state);
  const { addImage } = imageStore();

  const navigate = useNavigate();

  const handleNewPlayListClick = () => {
    navigate("/my-playlist/new");
  };

  const handlePlayListClick = (id) => () => {
    navigate(`/my-playlist/view/${id}`);
  };

  const handleScheduleIconClick = (id) => (e) => {
    setCurrentPlaylistId(id);
    setIsVisible((prev) => !prev);
  };

  const donwloadPlayListImage = useCallback(() => {
    console.log(playlists);
    for (let i = 0; i < playlists.length; i++) {
      const currentPlayList = playlists[i];
      console.log(currentPlayList);
      const image = currentPlayList?.image;

      if (!image) continue;
      const img = new Image();
      img.src = `${MEDIA_URL}/playlist_images/${image}`;
      img.crossOrigin = "anonymous";
      img.onload = () => {
        addImage(image);
      };
      img.onerror = () => {
        console.log("error");
      };
    }
  }, [addImage, playlists]);

  useEffect(() => {
    donwloadPlayListImage();
  }, [donwloadPlayListImage]);

  return (
    <>
      <div className={styles.header}>
        <p>My Playlist</p>
        {!isEmpty(playlists) && (
          <Button
            hasIcon
            iconType={ICONS.PLUS}
            className={styles.btn}
            onClick={handleNewPlayListClick}
          >
            New PlayList
          </Button>
        )}
      </div>

      <Header columns={["Playlist", "Songs", "Schedule"]} />
      {isLoading ? (
        <Loader />
      ) : !isEmpty(playlists) ? (
        <div className={styles.myPlayListPage}>
          <div className={styles.songsContainer}>
            {map(
              playlists,
              ({ name, songs, id, color, image, visibelePhoto }) => (
                <SongList
                  iconContainerClassName={styles.iconContainer}
                  color={color}
                  songName={name}
                  artist={size(songs)}
                  key={id + "my playlist"}
                  thumbNailClassName={styles.thumbnailClassName}
                  onClick={handlePlayListClick(id)}
                  image={image}
                  visibelePhoto={visibelePhoto}
                  iconType={ICONS.MY_SCHEDULE}
                  onIconClick={handleScheduleIconClick(id)}
                  isVisible={isVisible}
                  id={id}
                  currentPlaylistId={currentPlaylistId}
                  setIsVisible={setIsVisible}
                />
              )
            )}
          </div>
        </div>
      ) : (
        <div
          className={styles.myPlayListPage}
          style={{ display: "flex", justifyContent: "center" }}
        >
          <NillDisplaySchedule
            head="You do not have any schedules you can create one for you here"
            btnPlaceholder="Create Playlist"
            iconType={ICONS.MY_PLAYLIST}
            onBtnClick={handleNewPlayListClick}
          />
        </div>
      )}

      {isVisible && (
        <div
          className={styles.background}
          onClick={() => {
            setIsVisible(false);
          }}
        />
      )}
    </>
  );
};

export default MyPlayListPage;
