import { create } from "zustand";

import { Song } from "../types/song.types";

interface ImageStore {
  donwloadedImges: object;
  addImage: (imageId: string) => void;
}

const imageStore = create<ImageStore>((set) => ({
  donwloadedImges: new Set<Song>(),
  addImage: (imageId: string) => {
    set((state) => {
      const updatedObject = { ...state.donwloadedImges, [imageId]: true };
      return { donwloadedImges: updatedObject };
    });
  },
}));

export default imageStore;
