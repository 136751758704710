import * as React from 'react';
const LikeNavbar = (props) => (
  <svg
    width={26}
    height={26}
    viewBox="0 0 26 26"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18.749 14.2602L20.3228 12.4868C21.9364 10.6687 21.9364 7.68006 20.3228 5.86195C18.7629 4.10415 16.278 4.10415 14.718 5.86195L13 7.7978L11.282 5.86202C9.72203 4.10422 7.23714 4.10422 5.67715 5.86202C4.06363 7.68013 4.06363 10.6688 5.67715 12.4869L13 20.7383L18.749 14.2602ZM20.7493 14.2681L13 23L4.60493 13.5404C2.46502 11.1292 2.46502 7.21976 4.60493 4.80851C6.62419 2.53321 9.82732 2.40492 11.9801 4.42365C12.1087 4.54427 12.2336 4.67256 12.3542 4.80851L13 5.53612L13.6458 4.80844C13.7664 4.67251 13.8912 4.54425 14.0199 4.42364C16.1726 2.40485 19.3758 2.53311 21.3951 4.80844C23.535 7.21969 23.535 11.1291 21.3951 13.5404L20.7493 14.2681Z"
      fill="white"
    />
  </svg>
);
export default LikeNavbar;
