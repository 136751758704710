import { map } from "lodash";

export const getPlaylistUpdatePayload = (data) => {
  const { name, songs } = data;
  return {
    name,
    songs: map(songs, (song, i) => {
      return {
        id: song?.id,
        index: i,
      };
    }),
  };
};
