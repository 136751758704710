import * as React from 'react';

const SVGComponent = (props) => (
  <svg
    width={26}
    height={26}
    viewBox="0 0 26 26"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M20.1667 8.2H16.8333V7.4C16.8333 6.76348 16.5699 6.15303 16.1011 5.70294C15.6323 5.25286 14.9964 5 14.3333 5H12.6667C12.0036 5 11.3677 5.25286 10.8989 5.70294C10.4301 6.15303 10.1667 6.76348 10.1667 7.4V8.2H6.83333C6.61232 8.2 6.40036 8.28429 6.24408 8.43431C6.0878 8.58434 6 8.78783 6 9C6 9.21217 6.0878 9.41566 6.24408 9.56569C6.40036 9.71571 6.61232 9.8 6.83333 9.8H7.66667V18.6C7.66667 19.2365 7.93006 19.847 8.3989 20.2971C8.86774 20.7471 9.50363 21 10.1667 21H16.8333C17.4964 21 18.1323 20.7471 18.6011 20.2971C19.0699 19.847 19.3333 19.2365 19.3333 18.6V9.8H20.1667C20.3877 9.8 20.5996 9.71571 20.7559 9.56569C20.9122 9.41566 21 9.21217 21 9C21 8.78783 20.9122 8.58434 20.7559 8.43431C20.5996 8.28429 20.3877 8.2 20.1667 8.2ZM11.8333 7.4C11.8333 7.18783 11.9211 6.98434 12.0774 6.83431C12.2337 6.68429 12.4457 6.6 12.6667 6.6H14.3333C14.5543 6.6 14.7663 6.68429 14.9226 6.83431C15.0789 6.98434 15.1667 7.18783 15.1667 7.4V8.2H11.8333V7.4ZM17.6667 18.6C17.6667 18.8122 17.5789 19.0157 17.4226 19.1657C17.2663 19.3157 17.0543 19.4 16.8333 19.4H10.1667C9.94565 19.4 9.73369 19.3157 9.57741 19.1657C9.42113 19.0157 9.33333 18.8122 9.33333 18.6V9.8H17.6667V18.6Z"
      fill="white"
    />
  </svg>
);

export default SVGComponent;
