import * as React from 'react';
const Previous = (props) => (
  <svg
    width={26}
    height={26}
    viewBox="0 0 26 26"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M18.2 7.7998L10.1111 12.9998L18.2 18.1998V7.7998ZM10.1111 12.9998V7.7998H7.79999V18.1998H10.1111V12.9998Z"
      fill="white"
    />
  </svg>
);
export default Previous;
