import * as React from "react";
const Shuffle = (props) => (
  <svg
    width={26}
    height={26}
    viewBox="0 0 26 26"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M16.2307 15.6479C15.9279 15.545 15.5413 15.2767 15.1086 14.8099C14.7372 14.4181 14.354 13.9132 13.9621 13.363C13.868 13.4976 13.7744 13.6323 13.6803 13.7696C13.424 14.1435 13.1619 14.521 12.8948 14.8923C13.8278 16.1121 14.796 17.3098 16.2317 17.5073V18.9331L19.9336 16.6307L16.2312 14.3267V15.6479H16.2307ZM10.0597 11.0431C10.4302 11.4339 10.8134 11.9387 11.2063 12.489C11.3014 12.3506 11.3974 12.2144 11.4935 12.0729C11.7495 11.6982 12.0095 11.3268 12.2735 10.9586C11.2779 9.65745 10.2484 8.36736 8.64955 8.30558H6.93457V10.1538H8.64955C8.97049 10.1396 9.47372 10.41 10.0597 11.0426V11.0431ZM16.2307 10.2019V11.5411L19.9331 9.2371L16.2307 6.93311V8.33092C15.2978 8.4471 14.5525 9.04277 13.9033 9.72716C12.8267 10.8805 11.886 12.4563 10.9574 13.7088C10.0426 14.9958 9.12484 15.7615 8.64857 15.6997H6.93359V17.548H8.64857C9.7192 17.5321 10.5497 16.8794 11.2651 16.1259C12.3402 14.9725 13.2819 13.3973 14.21 12.1452C14.966 11.0801 15.7211 10.3814 16.2307 10.2019V10.2019Z"
      fill={props.fill || "white"}
    />
  </svg>
);
export default Shuffle;
