import { createSlice } from "@reduxjs/toolkit";
import { filter, includes, map } from "lodash";

import { getFormattedPlayLists } from "./playlists.helpers";

const INITIAL_STATE = {
  isLoading: false,
  playlists: [],
  error: null,
  currentPlayList: {},
  currentSongIndex: 0,
};

const playlists = createSlice({
  name: "playlists",
  initialState: INITIAL_STATE,
  reducers: {
    fetchPlayListsStart: (state) => {
      state.isLoading = true;
    },
    fetchPlayListsSuccess: (state, action) => {
      state.playlists = action.payload?.playlists;
      state.currentPlayList = getFormattedPlayLists(
        action.payload?.playlists,
        action.payload?.playlists?.[0]?.id
      );
    },
    fetchPlayListsFailure: (state, error) => {
      state.error = error;
    },
    setCurretPlayList: (state, action) => {
      state.currentPlayList = getFormattedPlayLists(
        state.playlists,
        action.payload
      );
    },
    setCurrentSongIndex: (state, action) => {
      state.currentSongIndex = action.payload;
    },

    addPlaylistStart: (state) => {
      state.isLoading = true;
    },

    addPlaylistSuccess: (state, action) => {
      const { payload } = action || {};
      return {
        ...state,
        isAuthenticated: true,
        isLoading: false,
        playlists: [
          {
            name: payload.formatedPlaylist.name,
            songs: payload.formatedPlaylist.songsForRedux,
            id: payload.id,
            schedule: null,
            is_favorite: false,
          },
          ...state.playlists,
        ],
      };
    },
    addPlaylistFailure: (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    },

    editPlaylist: (state, action) => {
      state.playlists = action.payload;
    },

    addSchedule: (state, action) => {
      const playlistIds = action.payload?.ids;
      const schedule = action.payload?.schedule;
      const updatedPlaylists = map(state.playlists, (item) => {
        if (includes(playlistIds, item?.id)) {
          const updatesSchedules = [schedule, ...(item?.schedule || [])];
          return {
            ...item,
            schedule: updatesSchedules,
          };
        }
        return item;
      });
      return {
        ...state,
        playlists: updatedPlaylists,
      };
    },

    addIgnoreToSongStart: (state) => {
      state.isLoading = true;
    },

    addIgnoreToSongSuccess: (state, action) => {
      const { payload } = action || {};

      const updatedPlaylist = map(state.playlists, (item) => {
        if (item.id === payload.playlistId) {
          const updatedSongs = map(item.songs, (song) => {
            if (song.id === payload.songId) {
              return {
                ...song,
                ignore: payload?.ignore,
              };
            }

            return song;
          });

          return {
            ...item,
            songs: updatedSongs,
          };
        }
        return item;
      });
      return {
        ...state,
        isAuthenticated: true,
        isLoading: false,
        playlists: updatedPlaylist,
      };
    },

    addIgnoreToSongFailure: (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    },

    uploadPlaylistImage: (state, action) => {
      //
    },

    addLikeToSongInPlaylist: (state, action) => {
      //
    },
    unLikeTheSongInPlaylist: (state, action) => {
      //
    },
  },
});

export const {
  fetchPlayListsFailure,
  fetchPlayListsSuccess,
  fetchPlayListsStart,
  setCurretPlayList,
  setCurrentSongIndex,
  addPlaylistStart,
  addPlaylistSuccess,
  addPlaylistFailure,
  editPlaylist,
  deletePlaylist,
  addSchedule,
  addIgnoreToSongFailure,
  addIgnoreToSongStart,
  addIgnoreToSongSuccess,
  uploadPlaylistImage,
  addLikeToSongInPlaylist,
  unLikeTheSongInPlaylist,
} = playlists.actions;

const playlistsReducer = playlists.reducer;

export default playlistsReducer;
