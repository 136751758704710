import React from "react";

import styles from "./likedPage.module.scss";
import LoadingSkeleton from "../../components/loadingSkeleton/LoadingSkeleton";

const Loader = () => (
  <div className={styles.second}>
    <LoadingSkeleton isHomepage />
    <LoadingSkeleton isHomepage />
    <LoadingSkeleton isHomepage />
    <LoadingSkeleton isHomepage />
    <LoadingSkeleton isHomepage />
    <LoadingSkeleton isHomepage />
    <LoadingSkeleton isHomepage />
    <LoadingSkeleton isHomepage />
    <LoadingSkeleton isHomepage />
    <LoadingSkeleton isHomepage />
    <LoadingSkeleton isHomepage />
  </div>
);

export default Loader;
