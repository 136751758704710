import React from "react";
import cx from "classnames";
import propTypes from "prop-types";
import { noop } from "lodash";

import ThumbNail from "../../thumbNail/ThumbNail";
import Button from "../../button/Button";

import styles from "./songListItem.module.scss";
import { MEDIA_URL } from "../../../axios";

const SongListItem = (props) => {
  const {
    song,
    songNameClassName,
    songArtistClassName,
    hasBtn,
    onItemClick,
    contentContianerClassName,
    onBtnClick,
    containerClassName,
  } = props;

  const { title, artist, color, imageFileName } = song || {};

  const handleBtnClick = (e) => {
    e.stopPropagation();
    onBtnClick(song);
  };

  return (
    <div
      className={cx(styles.songListItem, containerClassName)}
      onClick={onItemClick}
    >
      <div className={styles.leftContainer}>
        <ThumbNail
          color={color}
          name={title}
          className={styles.thumbNailClassName}
          imgSrc={`${MEDIA_URL}/songs_images/${imageFileName}`}
          hasImage={imageFileName}
          imageFileName={imageFileName}
        />
        <div className={cx(styles.contentContainer, contentContianerClassName)}>
          <p
            className={cx(styles.name, songNameClassName, {
              [styles.name2]: hasBtn,
            })}
          >
            {title}
          </p>
          <p
            className={cx(styles.artist, songArtistClassName, {
              [styles.name2]: hasBtn,
            })}
          >
            {artist}
          </p>
        </div>
      </div>
      <div className={styles.rightContainer}>
        {hasBtn && <Button onClick={handleBtnClick}>Add</Button>}
      </div>
    </div>
  );
};

SongListItem.propTypes = {
  song: propTypes.object,
  songNameClassName: propTypes.string,
  songArtistClassName: propTypes.string,
  hasBtn: propTypes.bool,
  onItemClick: propTypes.func,
  contentContianerClassName: propTypes.string,
  onBtnClick: propTypes.func,
  containerClassName: propTypes.string,
};

SongListItem.defaultProps = {
  song: {},
  songNameClassName: "",
  songArtistClassName: "",
  hasBtn: false,
  onItemClick: noop,
  contentContianerClassName: "",
  onBtnClick: noop,
  containerClassName: "",
};

export default SongListItem;
