import React, { memo, useCallback, useMemo, useState } from "react";
import propTypes from "prop-types";
import cx from "classnames";
import _noop from "lodash/noop";
import _map from "lodash/map";
import _get from "lodash/get";
import styles from "./dropdown.module.scss";
import { filter, get, head, noop } from "lodash";
import Icons from "../icons";
import { ICONS } from "../../constants/icons.constants";
import Checkbox from "../checkbox/Checkbox";
import { useSelector } from "react-redux";
import PlaylistSong from "../playlistSong/PlaylistSong";

const Dropdown = ({
  arr,
  setValue,
  defaultValue,
  placeholder,
  disabled,
  listWithCheckbox,
  playlistDropdown,
  setSelectedPlaylist,
  playlistDaysDropdown,
  isHideIcon,
  suffix = "",
}) => {
  const [open, setOpen] = useState(false);
  const [valueInDropdown, setValueInDropdown] = useState("");
  const [checked, setChecked] = useState(false);

  const onClickItem = () => {
    setChecked(!checked);
  };

  const { playlists } = useSelector((state) => state.playlists);

  const handleClick = useCallback(() => {
    setOpen(!open);
  }, [open]);

  const handleBackdrop = () => {
    setOpen(false);
  };

  const handleOnClick = useCallback(
    (ele) => {
      setValue(ele);
      setValueInDropdown(ele.label);
      setTimeout(() => {
        setOpen(false);
      }, 100);
    },
    [setValue]
  );

  const initialValue = useMemo(() => {
    const initialObj = filter(arr, (item) => item.value === defaultValue);
    return get(defaultValue, "label", get(head(initialObj), "label", ""));
  }, [arr, defaultValue]);

  return (
    <>
      {open && <div className={styles.backdrop} onClick={handleBackdrop}></div>}
      <div className={styles.dropdown}>
        <div
          className={cx(styles.dropdownBtn, {
            [styles.forSelected]: valueInDropdown !== "",
            [styles.active]: open || valueInDropdown !== "",
          })}
          onClick={!disabled ? handleClick : noop}
        >
          {valueInDropdown
            ? `${valueInDropdown} ${suffix}`
            : `${initialValue} ${suffix}` || placeholder}

          {!isHideIcon ? (
            <div className={cx([styles.icon], { [styles.iconRotate]: open })}>
              <Icons type={ICONS.DROPDOWN_ARROW} />
            </div>
          ) : (
            !open && (
              <div className={cx([styles.icon], { [styles.iconRotate]: open })}>
                <Icons type={ICONS.DROPDOWN_ARROW} />
              </div>
            )
          )}
          {/* <div className={cx([styles.icon], { [styles.iconRotate]: open })}>
            <Icons type={ICONS.DROPDOWN_ARROW} />
          </div> */}
        </div>

        <div
          onClick={onClickItem}
          className={cx({
            [styles.dropdownContent]: open,
            [styles.hide]: !open,
            [styles.active]: open || valueInDropdown !== "",
          })}
        >
          {playlistDropdown && !playlistDaysDropdown
            ? _map(playlists, ({ name, id }) => (
                <div
                  className={cx(styles.dropdownList, styles.playListStyle)}
                  key={id}
                >
                  <PlaylistSong
                    songName={name}
                    dropdownView
                    id={id}
                    setSelectedPlaylist={setSelectedPlaylist}
                  />
                </div>
              ))
            : !playlistDaysDropdown &&
              _map(arr, (ele) => (
                <div
                  className={cx(styles.dropdownList, {
                    [styles.withCheckbox]: listWithCheckbox,
                  })}
                  key={ele.id}
                  onClick={!disabled ? () => handleOnClick(ele) : noop}
                >
                  <p>{_get(ele, "label")}</p>
                  {listWithCheckbox && (
                    <Checkbox isChecked={checked} setIsChecked={setChecked} />
                  )}
                </div>
              ))}

          {playlistDaysDropdown &&
            _map(arr, (ele) => (
              <div className={cx(styles.dropdownList, styles.playListStyle)}>
                <PlaylistSong
                  songName={ele.label}
                  dropdownView
                  dropdownDaysView
                  id={ele.value}
                  setSelectedPlaylist={setSelectedPlaylist}
                />
              </div>
            ))}
        </div>
      </div>
    </>
  );
};

Dropdown.propTypes = {
  arr: propTypes.array,
  setValue: propTypes.func,
  defaultValue: propTypes.string,
  disabled: propTypes.bool,
  listWithCheckbox: propTypes.bool,
  playlistDropdown: propTypes.bool,
  setSelectedPlaylist: propTypes.func,
  playlistDaysDropdown: propTypes.bool,
  placeholder: propTypes.string,
  isHideIcon: propTypes.bool,
};

Dropdown.defaultProps = {
  playlistDaysDropdown: false,
  arr: [],
  setValue: _noop,
  setSelectedPlaylist: noop,
  defaultValue: "",
  disabled: false,
  listWithCheckbox: false,
  playlistDropdown: false,
  placeholder: "",
  isHideIcon: false,
};

export default memo(Dropdown);
