import LikeNavbar from "./LikeNavbar";
import Home from "./Home";
import MyPlaylist from "./MyPlaylist";
import MySchedule from "./MySchedule";
import Profile from "./Profile";
import MainLogo from "./MainLogo";
import Previous from "./Previous";
import Repeat from "./Repeat";
import Shuffle from "./Shuffle";
import Pause from "./Pause";
import Volume from "./Volume";
import MoreInfo from "./MoreInfo";
import DropdownArrow from "./DropdownArrow";
import Edit from "./Edit";
import EyeOn from "./EyeOn";
import EyeOff from "./EyeOff";
import LikeSongList from "./LikeSongList";
import Logout from "./Logout";
import Music from "./Music";
import Mute from "./Mute";
import Play from "./Play";
import RedDelete from "./RedDelete";
import Refresh from "./Refresh";
import Search from "./Search";
import Upload from "./Upload";
import Plus from "./Plus";
import Delete from "./Delete";
import BackArrow from "./BackArrow";
import WatchLater from "./WatchLater";
import Checkbox from "./Checkbox";
import IgnoreSong from "./IgnoreSong";
import IgnoreIcon from "./IgnoreIcon";
import Error from "./Error";

import { ICONS } from "../../constants/icons.constants";
import AddToWatchLater from "./AddToWatchLater";

export const ICON_NAME_VS_CMP = {
  [ICONS.HOME]: Home,
  [ICONS.LIKE_NAVBAR]: LikeNavbar,
  [ICONS.MY_PLAYLIST]: MyPlaylist,
  [ICONS.MY_SCHEDULE]: MySchedule,
  [ICONS.PROFILE]: Profile,
  [ICONS.MAIN_LOGO]: MainLogo,
  [ICONS.PAUSE]: Pause,
  [ICONS.SHUFFLE]: Shuffle,
  [ICONS.REPEAT]: Repeat,
  [ICONS.PREVIOUS]: Previous,
  [ICONS.VOLUME]: Volume,
  [ICONS.MORE_INFO]: MoreInfo,
  [ICONS.DROPDOWN_ARROW]: DropdownArrow,
  [ICONS.EDIT]: Edit,
  [ICONS.EYE_OFF]: EyeOff,
  [ICONS.EYE_ON]: EyeOn,
  [ICONS.LIKE_SONG_LIST]: LikeSongList,
  [ICONS.LOGOUT]: Logout,
  [ICONS.MUSIC]: Music,
  [ICONS.MUTE]: Mute,
  [ICONS.PLAY]: Play,
  [ICONS.RED_DELETE]: RedDelete,
  [ICONS.REFRESH]: Refresh,
  [ICONS.SEARCH]: Search,
  [ICONS.UPLOAD]: Upload,
  [ICONS.PLUS]: Plus,
  [ICONS.DELETE]: Delete,
  [ICONS.BACK_ARROW]: BackArrow,
  [ICONS.WATCH_LATER]: WatchLater,
  [ICONS.CHECKBOX]: Checkbox,
  [ICONS.IGNORE_SONG]: IgnoreSong,
  [ICONS.IGNORE_ICON]: IgnoreIcon,
  [ICONS.ERROR]: Error,
  [ICONS.ADD_TO_WATCH_LATER]: AddToWatchLater,
};
