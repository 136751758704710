import { filter, head, map } from 'lodash';
import { getRandomColor } from '../../helpers/generel.helpers';

export const getFormattedPlayLists = (playlists, id) => {
  const playlist = head(filter(playlists, (item) => item.id === id));
  const formattedSongs = map(playlist.songs, (item) => ({
    ...item,
    color: getRandomColor(),
  }));
  return {
    ...playlist,
    songs: formattedSongs,
  };
};
