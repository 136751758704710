import React, { memo, useCallback, useEffect } from "react";
import propTypes from "prop-types";
import cx from "classnames";
import { filter, map, noop, size, sortBy } from "lodash";

import Icons from "../icons";
import ThumbNail from "../thumbNail/ThumbNail";

import { ICONS } from "../../constants/icons.constants";

import styles from "./scheduleListitem.module.scss";
import PopUp from "../popUp";
import playlistStore from "../../zustand/playlist.store";
import { deleteSchedule, updateSchedule } from "../../actions/playlist.actions";
import { MEDIA_URL } from "../../axios";
import imageStore from "../../zustand/image.store";

const MAP_DAYS = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

const PlaylistSong = ({
  id,
  songName,
  scheduleName,
  totalHours,
  days,
  liked,
  dropdownContainer,
  schduleIndex,
  schedules,
  imageUrl,
  color,
}) => {
  const sortedDays = sortBy(days);

  const [isDeleteModalVisible, setIsDeleteModalVisible] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);

  const { updateSchedule: updateScheduleState } = playlistStore();

  const { addImage } = imageStore();

  const handleDeleteSchedule = async () => {
    setIsLoading(true);

    const newSchedules = filter(
      schedules,
      (item, index) => index !== schduleIndex
    );

    console.log(newSchedules);

    if (size(schedules) > 1) {
      await updateSchedule(id, {
        schedule: newSchedules,
      });
      updateScheduleState(newSchedules, id);
    } else {
      await deleteSchedule(id);
      updateScheduleState(null, id);
    }
    setIsLoading(false);
    setIsDeleteModalVisible(false);
  };

  const donwloadPlayListImage = useCallback(() => {
    const image = imageUrl;

    if (!image) return;
    const img = new Image();
    img.src = `${MEDIA_URL}/playlist_images/${image}`;
    img.crossOrigin = "anonymous";
    img.onload = () => {
      addImage(image);
    };
    img.onerror = () => {
      //
    };
  }, [addImage, imageUrl]);

  useEffect(() => {
    donwloadPlayListImage();
  }, [donwloadPlayListImage]);

  return (
    <div className={cx(styles.container, dropdownContainer)}>
      <div className={styles.img}>
        <ThumbNail
          name={songName || scheduleName}
          className={styles.imgThumb}
          hasImage={imageUrl}
          imgSrc={`${MEDIA_URL}/playlist_images/${imageUrl}`}
          color={color}
          imageFileName={imageUrl}
        />
      </div>

      <div className={cx(styles.extraInfo, styles.scheduleText)}>
        <p className={styles.text1}>{scheduleName}</p>
        <div className={styles.textBox}>
          <p className={styles.text2}>{totalHours + " " + "Hr"}</p>
          <p className={styles.text2}>
            {map(sortedDays, (item) => MAP_DAYS[item] + ",  ")}
          </p>
        </div>
      </div>

      <div className={styles.box}>
        <div
          className={cx(styles.icon, { [styles.like]: liked })}
          onClick={() => setIsDeleteModalVisible(true)}
        >
          <Icons type={ICONS.DELETE} />
        </div>
      </div>
      <PopUp
        isOpen={isDeleteModalVisible}
        setIsOpen={setIsDeleteModalVisible}
        title="Are you sure, 
      you want to delete this schedule ?"
        iconType={ICONS.RED_DELETE}
        btnPlaceholder="Delete"
        onSuccess={handleDeleteSchedule}
        onCancle={() => setIsDeleteModalVisible(false)}
        id={id}
        isLoading={isLoading}
      />
    </div>
  );
};

PlaylistSong.propTypes = {
  normalView: propTypes.bool,
  removeView: propTypes.bool,
  dropdownView: propTypes.bool,
  scheduleListView: propTypes.bool,
  songName: propTypes.string,
  artistName: propTypes.string,
  scheduleName: propTypes.string,
  totalHours: propTypes.string,
  days: propTypes.array,
  id: propTypes.string,
  removeSong: propTypes.func,
  playListView: propTypes.bool,
  setSelectedPlaylist: propTypes.func,
  dropdownDaysView: propTypes.bool,
  setIsStateChanged: propTypes.func,
};

PlaylistSong.defaultProps = {
  dropdownDaysView: false,
  normalView: false,
  setSelectedPlaylist: noop,
  removeView: false,
  dropdownView: false,
  playListView: false,
  scheduleListView: false,
  songName: "",
  artistName: "",
  scheduleName: "",
  totalHours: "",
  days: [],
  id: "",
  removeSong: noop,
  setIsStateChanged: noop,
};

export default memo(PlaylistSong);
