import * as React from 'react';
const Edit = (props) => (
  <svg
    width={26}
    height={26}
    viewBox="0 0 26 26"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M19.8839 5.94473C19.3957 5.45657 18.6043 5.45657 18.1161 5.94473L17.0607 7.00019L19 8.93953L20.0555 7.88407C20.5436 7.39591 20.5436 6.60446 20.0555 6.1163L19.8839 5.94473ZM21.1161 8.94473C22.1901 7.87079 22.1901 6.12958 21.1161 5.05564L20.9446 4.88407C19.8706 3.81013 18.1294 3.81013 17.0555 4.88407L5.46969 16.4699C5.37357 16.566 5.30538 16.6864 5.27241 16.8183L4.27241 20.8183C4.20851 21.0739 4.2834 21.3442 4.46969 21.5305C4.65597 21.7168 4.92634 21.7917 5.18192 21.7278L9.18192 20.7278C9.31379 20.6948 9.43423 20.6266 9.53035 20.5305L21.1161 8.94473ZM17.9394 10.0002L16 8.06085L6.67724 17.3836L6.03079 19.9694L8.61658 19.323L17.9394 10.0002Z"
      fill="white"
    />
  </svg>
);
export default Edit;
