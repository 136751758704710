import React, { memo } from 'react';
import propTypes from 'prop-types';

import _get from 'lodash/get';
import _isNil from 'lodash/isNil';
import { ICON_NAME_VS_CMP } from './constants';

const Icon = ({ type, ...rest }) => {
  const RenderIcon = _get(ICON_NAME_VS_CMP, type, null);

  if (_isNil(RenderIcon)) {
    return null;
  }

  return <RenderIcon {...rest} />;
};

Icon.propTypes = {
  type: propTypes.string,
};

Icon.defaultProps = {
  type: '',
};

export default memo(Icon);
