import musicPlayerReducer from './musicplayer/musicplayer.slice';
import playlistsReducer from './playlists/playlists.slice';
import songsReducer from './songs/songs.slice';
import userReducers from './user/user.slice';

const rootReducer = {
  user: userReducers,
  playlists: playlistsReducer,
  songs: songsReducer,
  musicPlayer: musicPlayerReducer,
};

export default rootReducer;
