import * as React from 'react';

const SVGComponent = (props) => (
  <svg
    width={26}
    height={26}
    viewBox="0 0 26 26"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path
      d="M13 2.16663C7.04163 2.16663 2.16663 7.04163 2.16663 13C2.16663 18.9583 7.04163 23.8333 13 23.8333C18.9583 23.8333 23.8333 18.9583 23.8333 13C23.8333 7.04163 18.9583 2.16663 13 2.16663ZM13 21.6666C8.22246 21.6666 4.33329 17.7775 4.33329 13C4.33329 8.22246 8.22246 4.33329 13 4.33329C17.7775 4.33329 21.6666 8.22246 21.6666 13C21.6666 17.7775 17.7775 21.6666 13 21.6666ZM13.5416 7.58329H11.9166V14.0833L17.55 17.55L18.4166 16.1416L13.5416 13.2166V7.58329Z"
      fill="#949494"
    />
  </svg>
);

export default SVGComponent;
