import axios from "../../axios";
import { getRefreshToken, getToken } from "../../helpers/generel.helpers";

const loginUser = (username, password) =>
  axios.post("/login", { username, password });

const fetchCustomerData = async () =>
  axios.get("v1/customer/data", {
    headers: {
      authorization: await getToken(),
    },
  });

const getNewTokenFromRefreshToken = async () =>
  axios
    .post("/refreshtoken", {
      refreshToken: await getRefreshToken(),
    })
    .then((result) => result?.data?.token);

export const userService = {
  loginUser,
  fetchCustomerData,
  getNewTokenFromRefreshToken,
};
